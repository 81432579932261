import React, { useState } from 'react';

import { BallotStatus, useBallots, ballotResultToString, Ballot } from '../hooks/use-ballots';
import { ContentDefinition, useCommitteeSiteContext } from '../hooks/use-committee-site-details';
import { PagingControls } from './PagingControls';
import { LoadingOverlay } from './LoadingOverlay';
import { BallotDetails } from './BallotDetails';
import { ContentHeader } from './ContentHeader';
import { Modal } from './Modal';


export function BallotsRenderer({ contentDef }: { contentDef: ContentDefinition }) {
	const { committeeSite } = useCommitteeSiteContext();
	const { ballots, error, currentPage, totalPages, switchPage, isLoading } = useBallots({ committeeSite, contentDef });
	const [selectedBallot, setSelectedBallot] = useState<Ballot | null>(null);

	if (error) {
		return (<>
			<ContentHeader contentDefinition={contentDef} forceTitle={true} />
			<pre>{error.toString()}</pre>
		</>);
	}

	return (<>
		<LoadingOverlay isLoading={isLoading}>
			<ContentHeader contentDefinition={contentDef} />
			<div className="table-responsive">
			{ballots?.length === 0 && <div>No Ballots</div>}
			{ballots && ballots?.length > 0 &&
				<table className="table">
					<thead>
						<tr>
							<th>Ballot ID</th>
							<th>Committee ID</th>
							<th>Status</th>
							<th>Results</th>
							<th>Opens</th>
							<th>Closes</th>
							<th>Description</th>
						</tr>
					</thead>
					<tbody>
						{ballots?.map(ballot =>
							<tr key={ballot.id}>
								<td>
									{ballot.status === BallotStatus.Open 
										? <a href={ballot.openLink} target="_blank" rel="noreferrer"><span className="modal-trigger">{ballot.id}</span></a>
										: <a role="button" onClick={() => setSelectedBallot(ballot)}><span className="modal-trigger">{ballot.id}</span></a>
									}
								</td>
								<td>{ballot.committeeIds.join(', ')}</td>
								<td>{BallotStatus[ballot.status]}</td>
								<td>{ballotResultToString(ballot.result)}</td>
								<td>{ballot.startingDate.toLocaleDateString(undefined, { timeZone: 'UTC' })}</td>
								<td>{ballot.closingDate.toLocaleDateString(undefined, { timeZone: 'UTC' })}</td>
								{ballot.status === BallotStatus.Open && ballot.filePath
									? <td><a href={ballot.filePath} target="_blank" rel="noreferrer">{ballot.name}</a></td>
									: <td>{ballot.name}</td>
								}
							</tr>
						)}
					</tbody>
				</table>
				}
			</div>
			<PagingControls currentPage={currentPage} totalPages={totalPages} switchPage={switchPage} forTable={true} />
		</LoadingOverlay>
		<Modal isOpen={!!selectedBallot} onClose={() => setSelectedBallot(null)}>
			<BallotDetails ballot={selectedBallot} />
		</Modal>
	</>);
}