import React from 'react';
import { clsx } from 'clsx';
import { Link, useParams } from 'react-router-dom';

import { Spinner } from './Spinner';
import { useRosters } from '../hooks/use-rosters';

export function RostersSideBar() {

	const { rosterSlug } = useParams();
	const { rosters, error } = useRosters();

	function scrollTop() {
		document.body.scrollTop = document.documentElement.scrollTop = 0;
	}

	return <div className="sidebar sidebar--without-lines">
		{error && <p>ERROR:<em>{error.toString()}</em></p>}
		{!rosters && <p><Spinner /></p>}
		{rosters && (
			<>
			<h2><Link to={'/rosters'}>My Rosters</Link></h2>
				<ul className="unlisted">
					{rosters.map(roster => (<li key={roster.id} className={clsx(roster.slug === rosterSlug && 'sidebar__current-page')}><Link to={`/rosters/${roster.slug}`} state={{ roster }} onClick={scrollTop}>{roster.name}</Link></li>))}
				</ul>
			</>
		)}
	</div>
}