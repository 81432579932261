import React, { MouseEvent, PropsWithChildren } from 'react';

import { DocumentLibraryItem, DocumentLibraryFile, DocumentLibraryFolder, DocumentType } from '../hooks/use-document-library';
import { useCurrentUser } from '../hooks/use-current-user';
import { downloadFileById } from '../utils/file-utils';



export function DocumentLink({ fileOrFolder, onFolderClicked, children }: PropsWithChildren<{ fileOrFolder: DocumentLibraryFolder, onFolderClicked?: (folder: DocumentLibraryItem) => void }>): React.JSX.Element;
export function DocumentLink({ fileOrFolder, siteId, children }: PropsWithChildren<{ fileOrFolder: DocumentLibraryFile, siteId: string }>): React.JSX.Element;
export function DocumentLink({ fileOrFolder, siteId, onFolderClicked, children }: PropsWithChildren<{ fileOrFolder: DocumentLibraryItem, siteId?: string, onFolderClicked?: (folder: DocumentLibraryItem) => void }>): React.JSX.Element;
export function DocumentLink({ fileOrFolder, siteId, onFolderClicked, children }: PropsWithChildren<{ fileOrFolder: DocumentLibraryItem, siteId?: string, onFolderClicked?: (folder: DocumentLibraryItem) => void }>) {
	const { user } = useCurrentUser();

	if (fileOrFolder.type === DocumentType.Folder && onFolderClicked) {
		return (
			<a role="button" onClick={() => onFolderClicked.call(null, fileOrFolder)}>
				{children ?? fileOrFolder.name}
			</a>
		);
	}

	if (user) {
		return (
			<a href={fileOrFolder.url} target="_blank" rel="noreferrer">
				{children ?? fileOrFolder.name}
			</a>
		);
	}

	const downloadUrl = `/api/documents/download/${siteId}/${fileOrFolder.driveId}/${fileOrFolder.id}?filename=${fileOrFolder.name}`;

	return (
		<a href={downloadUrl} target="_blank" rel="noreferrer" onClick={e => anonUserDownloadFile(e, fileOrFolder, siteId)}>
			{children ?? fileOrFolder.name}
		</a>);
}

async function anonUserDownloadFile(e: MouseEvent, file: DocumentLibraryItem, siteId?: string) {
	e.preventDefault();
	e.stopPropagation();

	if (siteId) {
		await downloadFileById(siteId, file.driveId!, file.id, file.name);
	}
}