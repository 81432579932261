import React from 'react';
import { Event } from '../hooks/use-calendar-events';
import { dateFormatter, timeFormatter } from '../utils/date-utils';

export function CalendarDetails({details}:{details:Event}) {
	return (<>
		<h2 className="h4-styling">{details.title}</h2>
		<ul className="modal__content__event-location unlisted">
			<li><i className="fa-solid fa-location-dot"></i>{details.location}</li>
			<li><i className="fa-solid fa-calendar-days"></i>
				<DateTimeLabel date={details.startTime} allDayEvent={details.allDayEvent} /> - <DateTimeLabel date={details.endTime} allDayEvent={details.allDayEvent} />
			</li>
		</ul>
		<div className="table-responsive">
			<table className="table table--simple">
				<tbody>
					<tr>
						<td>Description</td>
						<td><span dangerouslySetInnerHTML={{ __html: details.description }} /></td>
					</tr>
					<tr>
						<td>All Day Event</td>
						<td>{details.allDayEvent ? 'Yes' : 'No'}</td>
					</tr>
					<tr>
						<td>Recurrence</td>
						<td>{details.recurrence ? 'Yes' : 'No'}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</>);
};

function DateTimeLabel({ date, allDayEvent }: { date: Date, allDayEvent: boolean }) {

	return <><span>{dateFormatter(date, allDayEvent)}</span>{!allDayEvent && (<> {timeFormatter(date)}</>)}</>
}