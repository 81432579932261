import React from 'react';

import { Ballot, BallotStatus, ballotResultToString, useBallotDetails } from '../hooks/use-ballots';
import { LoadingOverlay } from './LoadingOverlay';

export function BallotDetails({ ballot }: { ballot: Ballot | null }) {

	const { ballotDetails, error } = useBallotDetails(ballot?.id);

	if (!ballot) {
		return (<></>);
	}

	if (error) {
		return (<p><em>{error.toString()}</em></p>);
	}

	let responseRate = 0.0;
	let approvalRate = 0.0;

	const totalBallots = ballotDetails?.totalBallots ?? 0;
	const affirmative = ballotDetails?.affirmative ?? 0;
	const negative = ballotDetails?.negative ?? 0;
	const abstain = ballotDetails?.abstain ?? 0;

	if (totalBallots > 0) responseRate = toTwoDecimalPlaces(100.00 * ((affirmative + negative + abstain)) / totalBallots);
	if ((affirmative + negative) > 0) approvalRate = toTwoDecimalPlaces(100.00 * affirmative / (affirmative + negative));

	const consensus = (responseRate > 50.00) && (approvalRate >= 66.67) ? 'YES' : 'NO';

	return (<>
		<h2 className="h4-styling">Ballot {ballot?.id}</h2>
		<h3 className="h5-styling">Ballot Information</h3>
		<div className="table-responsive">
			<table className="table table--simple">
				<tbody>
					<tr>
						<td>ID</td>
						<td>{ballot.id}</td>
					</tr>
					<tr>
						<td>Name</td>
						<td>{ballot.name}</td>
					</tr>
					<tr>
						<td>Start Date</td>
						<td>{ballot.startingDate.toLocaleDateString()}</td>
					</tr>
					<tr>
						<td>End Date</td>
						<td>{ballot.closingDate.toLocaleDateString()}</td>
					</tr>
					<tr>
						<td>Associate</td>
						<td>{ballot.associateName}</td>
					</tr>
					<tr>
						<td>Coordinator</td>
						<td>{ballot.coordinatorName}</td>
					</tr>
					<tr>
						<td>Proposal</td>
						<td>{ballot.proposal}</td>
					</tr>
					<tr>
						<td>Status</td>
						<td>{BallotStatus[ballot.status]}</td>
					</tr>
					<tr>
						<td>Results</td>
						<td>{ballotResultToString(ballot.result)}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<h3 className="h5-styling">Ballot Summary</h3>
		<LoadingOverlay isLoading={!ballotDetails}>
			<div className="table-responsive">
				<table className="table">
					<thead>
						<tr>
							<th>Statistics</th>
							<th>Outcome</th>
							<th>Note</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Affirmative</td>
							<td>{ballotDetails?.affirmative}</td>
							<td></td>
						</tr>
						<tr>
							<td>Negative</td>
							<td>{ballotDetails?.negative}</td>
							<td></td>
						</tr>
						<tr>
							<td>Abstain</td>
							<td>{ballotDetails?.abstain}</td>
							<td></td>
						</tr>
						<tr>
							<td>Did Not Vote</td>
							<td>{ballotDetails?.didNotVote}</td>
							<td></td>
						</tr>
						<tr>
							<td>Total Responses</td>
							<td>{ballotDetails?.totalResponses}</td>
							<td></td>
						</tr>
						<tr>
							<td>Total Ballots</td>
							<td>{ballotDetails?.totalBallots}</td>
							<td></td>
						</tr>
						<tr>
							<td>Response Rate</td>
							<td>{responseRate.toFixed(2)}%</td>
							<td>Must be &gt; 50%</td>
						</tr>
						<tr>
							<td>Approval Rate</td>
							<td>{approvalRate.toFixed(2)}%</td>
							<td>Must be &gt;= 66.67%</td>
						</tr>
						<tr>
							<td>Consensus</td>
							<td>{consensus}</td>
							<td></td>
						</tr>
					</tbody>
				</table>
			</div>
		</LoadingOverlay>
	</>);
}

function toTwoDecimalPlaces(value: number) {
	return Math.round(value * 100) / 100;
}