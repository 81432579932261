import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Outlet, useLocation } from 'react-router-dom';

import { Header } from '../components/Header';
import { CommitteesSideBar } from '../components/CommitteesSideBar';
import { Footer } from '../components/Footer';
import { RostersSideBar } from '../components/RostersSideBar';
import { trackPageView } from '../utils/gtag';
import { CurrentUserContext, useRootFetchCurrentUser } from '../hooks/use-current-user';

import styles from './LayoutPage.module.scss';
import clsx from 'clsx';
import { useBanner } from '../hooks/use-banner';

export function LayoutPage() {

	const { pathname } = useLocation();
	const currentUserData = useRootFetchCurrentUser();
	const [isSideNavCollapsed, setIsSideNavCollapsed] = useState(false);
	const banner = useBanner(currentUserData.user);
	
	const isRosters = pathname.startsWith('/rosters');
	useEffect(() => {
		trackPageView(pathname);
	}, [pathname])

	return (
		<>
			<CurrentUserContext.Provider value={currentUserData}>
				<Header />
				<main>
					<div className={styles.container}>
						<div className={clsx(styles.sidebar, isSideNavCollapsed && styles.collapsed)}>
							<button className={styles.collapseBtn} title="Collapse" onClick={() => setIsSideNavCollapsed(!isSideNavCollapsed)}>
							{isSideNavCollapsed && <i className="fa-solid fa-bars"></i> }
							{!isSideNavCollapsed && <i className="fa-solid fa-chevron-left"></i>}
							</button>
							<div className={styles.sidebarContent}>
								{isRosters ? <RostersSideBar /> : <CommitteesSideBar />}
							</div>
						</div>
						<Container fluid className={styles.content}>
							{banner && <div className="alert alert-warning" style={{marginLeft: -30, marginRight: -30, borderRadius: 0, textAlign: 'center'}}>{banner.message}</div>}
							<div>
							<Outlet/>
							</div>
						</Container>
					</div>
				</main>
				<Footer />
			</CurrentUserContext.Provider>
		</>
	);
}
