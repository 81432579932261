import React from 'react';
import { Committee } from '../hooks/use-committee-association';
import { useCommitteeAssociation } from '../hooks/use-committee-association';
import { Spinner } from '../components/Spinner';

export function CommitteeAssociationList({ committeeIds }: { committeeIds: number[] | undefined }) {
		
	const { data, isLoading, error } = useCommitteeAssociation(committeeIds);

	if (isLoading) return <p><Spinner /></p>;

	const dataCopy: Committee[] = Object.assign([], data);

	if (dataCopy?.length === 0)
	{
		return <div>No associated committees found</div>;
	}

	
	//this is very rare case scenario where one or more input committeeId(s) passed there is no committee(s) exist in RIK
	//output array length is shorter than input array length
	if ((committeeIds && committeeIds.length > 0 && dataCopy.length > 0) && (dataCopy.length < committeeIds.length)) {
		committeeIds?.forEach(function (id) {
			let idExist: boolean = false;
			for (let i = 0; data && i < data.length; i++) {
				if (data[i].commmitteeId === id) {
					idExist = true;
					break;
				}
			}
			if (!idExist) {
				dataCopy.push({
					commmitteeId: id
				});
			}
		});
	}
	

	return (<div id="menu" className="list-group">
		{dataCopy && dataCopy?.map(cmt => {
			let sDate: any = (cmt.startDate ? new Date(cmt.startDate).toLocaleDateString() : null);
			let eDate: any = (cmt.endDate ? new Date(cmt.endDate).toLocaleDateString() : null);

			return cmt && (
				<div className="menu-item menu-level-0 sub-menu list-group-item" key={cmt.commmitteeId}>
					<div><strong>{cmt.commmitteeId}</strong></div>
					<div className="text-muted">{cmt.committeeName}</div>
					<div className="h6" style={(cmt.isActive === null || !cmt.isActive) ? { color: 'red' } : {}}>{(cmt.isActive !== null && !cmt.isActive) ? `*Inactive Committee (${sDate} - ${eDate})`  : ''}</div>
					
				</div>
			);
		})}
	</div>);
}

