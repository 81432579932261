const localDateFormatter = new Intl.DateTimeFormat('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
const localTimeFormatter = new Intl.DateTimeFormat('en-US', { timeStyle: 'short' });

const utcDateFormatter = new Intl.DateTimeFormat('en-US', { month: '2-digit', day: '2-digit', year: 'numeric', timeZone: 'UTC' });

export function dateFormatter(date: Date, allDayEvent: boolean) {
	return allDayEvent ? utcDateFormatter.format(date) : localDateFormatter.format(date);
}

export function timeFormatter(date: Date) {
	return localTimeFormatter.format(date);
}