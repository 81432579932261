import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { CommitteeSite, useCommitteeSites, isRootSite } from './use-committee-sites';

export interface CommitteeSiteTree {
	committeeSite: CommitteeSite;
	subTrees: CommitteeSiteTree[];
	expanded: boolean;
	active: boolean;
}

export function useCommitteeSiteTree() {

	const { committeeSites, isLoading, error } = useCommitteeSites();
	const pathname = useLocation().pathname.slice(1);
	const [committeeSiteTrees, setCommitteeSiteTrees] = useState<CommitteeSiteTree[]>();
	const [currentSitePath, setCurrentSitePath] = useState<CommitteeSiteTree[]>();
	const [siteFilter, setSiteFilter] = useState('');

	useEffect(() => {
		let trees: CommitteeSiteTree[] = committeeSites?.filter(isRootSite).map(c => ({ committeeSite: c, subTrees: [], expanded: true, active: false })) ?? [];
		let childQueue = committeeSites?.filter(cs => !isRootSite(cs)) ?? [];

		const parentQueue = [...trees];

		while (parentQueue.length) {
			const parentTree = parentQueue.shift()!;
			const children = childQueue.filter(child => parentTree.committeeSite.siteId === child.parentSiteId);
			const matchingChildIds = children.map(c => c.siteId);
			childQueue = childQueue.filter(child => !matchingChildIds.includes(child.siteId));
			parentTree.subTrees = children.map(cs => ({ committeeSite: cs, subTrees: [], expanded: false, active: false }));
			parentQueue.push(...parentTree.subTrees);
		}

		if (childQueue.length) {
			console.group('Unmatched children');
			for (const child of childQueue) {
				const parent = committeeSites?.find(cs => cs.siteId === child.parentSiteId);
				if (parent) console.log(`Parent '${parent.name}' found for '${child.name}'`, parent);
				else console.log(`No parent found for '${child.name}'`);
			}
			console.groupEnd();
		}

		if (siteFilter) {
			trees = filterTrees(trees, siteFilter.toLowerCase());
			trees = expandMatchingTrees(trees, siteFilter);
		}

		trees = sortTrees(trees);
		trees = trimEmptyTrees(trees);

		if (trees.length === 1 && isRootSite(trees[0].committeeSite)) {
			trees = trees[0].subTrees;
		}

		const ancestors = depthFirstSearch(trees, tree => tree.committeeSite.slug === pathname);
		expandActive(ancestors);
		setCurrentSitePath(ancestors);

		setCommitteeSiteTrees(trees);
	}, [committeeSites, pathname, siteFilter]);

	return {
		isLoading,
		error,
		committeeSiteTrees,
		currentSitePath,
		siteFilter,
		setSiteFilter,
	};
}

function sortTrees(committeeTrees: CommitteeSiteTree[]) {
	committeeTrees.sort((a, b) => {
		if (a.committeeSite.name < b.committeeSite.name) return -1;
		if (a.committeeSite.name > b.committeeSite.name) return 1;
		return 0;
	});
	for (const tree of committeeTrees) sortTrees(tree.subTrees);

	return committeeTrees;
}

function filterTrees(committeeTrees: CommitteeSiteTree[], siteFilter: string) {
	const matchingTrees: CommitteeSiteTree[] = [];

	for (const tree of committeeTrees) {
		const matchingSubTrees = filterTrees(tree.subTrees, siteFilter);
		tree.subTrees = matchingSubTrees;
		if (tree.subTrees.length) matchingTrees.push(tree);
		else if (tree.committeeSite.slug && tree.committeeSite.name.toLowerCase().includes(siteFilter)) {
			matchingTrees.push(tree);
		}
	}

	return matchingTrees;
}

function expandActive(ancestors: CommitteeSiteTree[]) {
	for (const ancestor of ancestors) ancestor.expanded = true;

	// Mark the tree as active so we can highlight the currently selected committeeSite.
	if (ancestors.length) ancestors[ancestors.length - 1].active = true;
}

function trimEmptyTrees(committeeTrees: CommitteeSiteTree[]) {
	const validTrees: CommitteeSiteTree[] = [];
	for (const tree of committeeTrees) {
		if (tree.subTrees.length) tree.subTrees = trimEmptyTrees(tree.subTrees);

		if (tree.committeeSite.slug || tree.subTrees.length) validTrees.push(tree);
	}

	return validTrees;
}

function expandMatchingTrees(committeeTrees: CommitteeSiteTree[], siteFilter: string) {
	for (const tree of committeeTrees) {
		tree.expanded = true;
		expandMatchingTrees(tree.subTrees, siteFilter);
	}

	return committeeTrees;
}

function depthFirstSearch(committeeTrees: CommitteeSiteTree[], findFunc: (tree: CommitteeSiteTree) => boolean): CommitteeSiteTree[] {
	for (const tree of committeeTrees) {
		if (findFunc(tree)) return [tree];
		const result = _depthFirstSearch(tree.subTrees, findFunc, [tree]);
		if (result) return result;
	}

	return [];
}

function _depthFirstSearch(committeeTrees: CommitteeSiteTree[], findFunc: (tree: CommitteeSiteTree) => boolean, ancestors: CommitteeSiteTree[]): CommitteeSiteTree[] | undefined {
	for (const tree of committeeTrees) {
		if (findFunc(tree)) return [...ancestors, tree];
		const result = _depthFirstSearch(tree.subTrees, findFunc, [...ancestors, tree]);
		if (result) return result;
	}
	return undefined;
}