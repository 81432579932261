import React, { useRef, useEffect, useState } from 'react';
import { useContainerSize } from '../hooks/use-container-size';

const MIN_PAGES = 5;
const MAX_PAGES = 40;

const ESTIMATED_ARROW_WIDTH = 36;
const ESTIMATED_NUMBER_WIDTH = 30;

export function PagingControls({ totalPages, currentPage, switchPage, forTable }: { totalPages?: number, currentPage?: number, switchPage: (page: number) => void, forTable?: boolean }) {
	const containerRef = useRef<HTMLDivElement>(null);
	const [containerSize] = useContainerSize(containerRef);

	let [pages, setPages] = useState<number[] | undefined>(undefined);


	useEffect(() => {

		let maxPagesToShow = MAX_PAGES;
		if (containerSize) {
			maxPagesToShow = Math.floor((containerSize.width - (2 * ESTIMATED_ARROW_WIDTH)) / ESTIMATED_NUMBER_WIDTH);
		}

		maxPagesToShow = Math.max(MIN_PAGES, Math.min(maxPagesToShow, MAX_PAGES));

		if (totalPages && typeof currentPage === 'number') {
			let pages = Array(totalPages).fill(0).map((_, i) => i);
	
			if (pages.length > maxPagesToShow) {
				const start = Math.max(0, currentPage - Math.floor(maxPagesToShow / 2));
				const end = Math.min(start + maxPagesToShow, totalPages - 1);
				pages = pages.slice(start, end);
			}

			setPages(pages);
		}

	}, [totalPages, currentPage, containerSize]);

	

	return (<div className="pagination-container" ref={containerRef}>
		<ul className={`pagination unlisted inline ${forTable ? 'pagination--table' : ''}`}>
			{pages && (<>
				<li className="pagination__prev"><a role="button" onClick={() => switchPage((currentPage ?? 0) - 1)}><i className="fa-solid fa-chevron-left"></i><span className="hidden-for-accessibility">Previous</span></a></li>
				{pages.map(i => {
					return i === currentPage ? (<li key={i} className="pagination__current">{i + 1}</li>) : (<li key={i}><a role="button" onClick={() => switchPage(i)} >{i + 1}</a></li>);
				})}
				<li className="pagination__next"><a role="button" onClick={() => switchPage((currentPage ?? 0) + 1)}><i className="fa-solid fa-chevron-right"></i><span className="hidden-for-accessibility">Next</span></a></li>
			</>)}
		</ul>
	</div>);
}