import React, { useState, useRef, useMemo, PropsWithChildren } from 'react';
import clsx from 'clsx';
import { Col, Row } from 'reactstrap';

import { LibraryContentDefinition, useCommitteeSiteContext, DocumentLibrarySortBy } from '../hooks/use-committee-site-details'
import { useDocumentLibrary, DocumentType, DocumentLibraryItem, DocumentLibraryFolder, getDocumentIcon } from '../hooks/use-document-library';
import { CommitteeSite } from '../hooks/use-committee-sites';
import { useCurrentUser, User } from '../hooks/use-current-user';
import { PagingControls } from './PagingControls';
import { LoadingOverlay } from './LoadingOverlay';
import { ContentHeader, ContentHeaderButton } from './ContentHeader';
import { DocumentLink } from './DocumentLink';

import FolderIcon from '../assets/icon-folder.svg';

export function DocumentLibraryRenderer({ contentDef }: { contentDef: LibraryContentDefinition }) {
	const { committeeSite, committeeSiteDetails } = useCommitteeSiteContext();
	let { documents, error, currentPage, totalPages, currentPath, switchPage, uploadDocument, openFolder, goBack, sortBy, sortDir, setSort, canSort, isLoading, accessDenied } = useDocumentLibrary(committeeSite?.siteId, contentDef);
	const { user } = useCurrentUser();
	const [file, setFile] = useState<File>();
	const [showFileUpload, setShowFileUpload] = useState(false);
	const [showFileSelector, setShowFileSelector] = useState(false);
	const [uploadMessage, setUploadMessage] = useState(false);
	const inputRef = useRef<HTMLInputElement>(null);

	function handleFileSelected(event: React.ChangeEvent<HTMLInputElement>) {
		if ((event.target.files?.length) && (event.target.files?.length > 0)) {
			setFile(event.target.files[0]);
			setShowFileUpload(true);
		}
	}

	async function submitUpload() {
		if (!file) return;

		setShowFileSelector(false);
		setShowFileUpload(false);
		setUploadMessage(true);
		await uploadDocument(file);

		if (inputRef.current) {
			const inputEl = inputRef.current as HTMLInputElement;
			inputEl.value = '';
		}
	}

	function openFileUploadDialog()
	{
		setShowFileSelector(true);

		if (inputRef.current !== null){
			inputRef.current.click();
		}
	}

	function cancelUpload() {
		setShowFileSelector(false);
		setShowFileUpload(false);
		setUploadMessage(false);

		if (inputRef.current) {
			const inputEl = inputRef.current as HTMLInputElement;
			inputEl.value = '';
		}
	}

	const isOwner = committeeSiteDetails?.userRoles.isOwner ?? false;
	const isEditor = committeeSiteDetails?.userRoles.isEditor ?? false;

	if (error) {
		return (<>
			<ContentHeader contentDefinition={contentDef} forceTitle={true} />
			<pre>{error.toString()}</pre>
		</>);
	}

	if (contentDef.documentLibraryParameters?.customSecurity && (!user || accessDenied)) {
		return (<></>);
	}
	
	return <>
		<LoadingOverlay isLoading={isLoading}>
			<ContentHeader contentDefinition={contentDef} >
			{user && (isOwner || isEditor) &&
				<ContentHeaderButton icon="fa-solid fa-upload" label="Upload Document" onClick={() => openFileUploadDialog()} />
			}
			</ContentHeader>
			{user && (isOwner || isEditor) && uploadMessage && 
				<p className="p-small">File is uploading. This may take a minute. Please refresh your browser to see the new file if it does not appear.</p>
			}
			{user && (isOwner || isEditor) &&
				<Row className={showFileSelector? undefined : 'hidden-for-accessibility'}>
						<Col>
							<p><input ref={inputRef} type="file" onChange={handleFileSelected} /></p>
							{showFileUpload &&
								<>
									<button type="button" className="btn" onClick={submitUpload}>Upload</button>&nbsp;
									<button type="button" className="btn" onClick={cancelUpload}>Cancel</button>
								</>
							}
						</Col>
				</Row>
			}
			{documents && (
				<>
					<DocumentsBreadcrumb currentPath={currentPath} openFolder={openFolder} />
					<DocumentsTable {...{ documents, committeeSite, contentDef, user, currentPath, openFolder, goBack, sortBy, sortDir, setSort, canSort }} />
				</>
			)}
			<PagingControls {...{ currentPage, switchPage, totalPages, forTable: true }} />
		</LoadingOverlay>
	</>;
}

function DocumentsBreadcrumb({ currentPath, openFolder }: { currentPath: DocumentLibraryFolder[], openFolder: (folder: DocumentLibraryFolder | null) => void }) {
	const docLibBreadcrumb = useMemo(() => {
		if (!currentPath?.length) return undefined;

		const names = [...currentPath ?? []].reverse()
			.map((current, index) => (<li key={current.id}>{(index === currentPath.length - 1) ? current.name : (<a href="javascript: void(0);" onClick={() => openFolder(current)}>{current.name}</a>)}</li>));

		return [(<li key={0}><a href="javascript: void(0);" onClick={() => openFolder(null)}>Root</a></li>), names];
	}, [currentPath, openFolder]);

	return (
		<Row>
			<Col>
				<div className="breadcrumbs mt-0">
					<ul className="breadcrumbs__nav unlisted inline">
						{docLibBreadcrumb}
					</ul>
				</div>
			</Col>
		</Row>
	);
}

function DocumentsTable({ documents, committeeSite, contentDef, user, currentPath, openFolder, goBack, sortBy, sortDir, setSort, canSort }: { documents: DocumentLibraryItem[], committeeSite: CommitteeSite | undefined, contentDef: LibraryContentDefinition, user: User|undefined, currentPath: DocumentLibraryFolder[], openFolder: (folder: DocumentLibraryFolder) => void, goBack: () => void, sortBy: DocumentLibrarySortBy, sortDir: number, setSort: (sortBy: DocumentLibrarySortBy) => void, canSort: boolean }) {
	return (
		<div className="table-responsive">
			<table className="table table--folders" aria-labelledby="tableLabel">
				<thead>
					<tr>
						<th>Type</th>
						<th><SortableHeader column={DocumentLibrarySortBy.Name} {...{sortBy, sortDir, setSort, canSort }}>Name</SortableHeader></th>
						<th><SortableHeader column={DocumentLibrarySortBy.LastModified} {...{sortBy, sortDir, setSort, canSort }}>Last Modified</SortableHeader></th>
						<th>Modified By</th>
					</tr>
				</thead>
				<tbody>
					{currentPath.length > 0 &&
						<tr className="navigate-parent-folder">
							<td>
								<span><img src={FolderIcon} alt="Folder" /></span>
							</td>
							<td>
								<a role="button" onClick={() => goBack()}>. .</a>
							</td>
							<td></td>
							<td></td>
						</tr>
					}
					{documents.map(fileOrFolder =>
						<tr key={fileOrFolder.id}>
							<td>
								<span>
									<img src={getDocumentIcon(fileOrFolder.type)} width="16" alt="#" />
								</span>
							</td>
							<td>
								{fileOrFolder.type === DocumentType.Folder &&
									<DocumentLink fileOrFolder={fileOrFolder} onFolderClicked={() => openFolder(fileOrFolder)}>{fileOrFolder.name} ({fileOrFolder.childCount})</DocumentLink>
								}
								{committeeSite && fileOrFolder.type !== DocumentType.Folder &&
									<DocumentLink fileOrFolder={fileOrFolder} siteId={committeeSite.siteId} />
								}
							</td>
							<td title={fileOrFolder.lastModified.toLocaleString()}>{fileOrFolder.lastModified.toLocaleDateString()}</td>
							<td>{fileOrFolder.lastModifiedBy}</td>
						</tr>
					)}
					{documents?.length === 0 && <tr><td colSpan={4}>No Documents</td></tr>}
				</tbody>
			</table>
		</div>
	);
}

function SortableHeader({ column, sortBy, sortDir, setSort, canSort, children }: PropsWithChildren<{ column: DocumentLibrarySortBy, sortBy: DocumentLibrarySortBy, sortDir: number, setSort: (sortBy: DocumentLibrarySortBy) => void, canSort: boolean }>) {
	if (!canSort) return (<>{children}</>);
	
	const iconClasses = sortBy !== column ? 'fa-sort' : (sortDir > 0 ? 'fa-sort-up' : 'fa-sort-down');
	return (<a style={{textDecoration: 'none', color: 'inherit'}} href="javascript: void(0);" onClick={() => setSort(column)}>{children} <i className={`fa-solid ${iconClasses}`} /></a>);
}