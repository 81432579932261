import React from 'react';
import { usePageTitle } from '../hooks/use-page-title';

export function NotFoundPage() {

	usePageTitle('Not Found');

	return (<div className="content-area">
		<div className="breadcrumbs"></div>
		<h1>404 Not Found</h1>
		<p>The page you requested could not be found.</p>
	</div>);
}