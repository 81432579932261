

/**
 * Iterates through an array in chunks of size n
 *
 * @export
 * @template T The type of the array
 * @param {T[]} arr The array to iterate through
 * @param {number} n The size of the chunks
 * @return {*}  {Generator<T[], void>} A generator that yields chunks of size n
 */
export function* chunks<T>(arr: T[], n: number): Generator<T[], void> {
	for (let i = 0; i < arr.length; i += n) {
		yield arr.slice(i, i + n);
	}
}