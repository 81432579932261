import useSWR from 'swr';
import { CommitteeSite } from './use-committee-sites';
import { ContentDefinition } from './use-committee-site-details';
import { dateParser, deferredUpdate } from '../utils/swr-config';
import { useState } from 'react';
import { PagedResult } from '../utils/paging-utils';

export enum BallotStatus {
	Open,
	Closed
}

export enum BallotResult {
	InProgress = 0,
	Pass = 1,
	Fail = 2,
	Recirculation = 3,
	ResolutionRequired = 4,
	FailResolutionRequired = 5,
	Unknown = 9999,
}

export function ballotResultToString(result: BallotResult) {
	switch (result) {
		case BallotResult.InProgress:
			return "In Progress";
		case BallotResult.Pass:
			return "Pass";
		case BallotResult.Fail:
			return "Fail";
		case BallotResult.Recirculation:
			return "Recirculation";
		case BallotResult.ResolutionRequired:
			return "Resolution Required";
		case BallotResult.FailResolutionRequired:
			return "Fail Resolution Required";
	}
}

export interface Ballot {
	id: number;
	name: string;
	openLink?: string;
	status: BallotStatus;
	result: BallotResult;
	startingDate: Date;
	closingDate: Date;
	proposal: string;
	associateName: string;
	committeeIds: string[]
	coordinatorName: string;
	filePath?: string;
}

export interface BallotDetails {
	ballotId: number;
	affirmative: number;
	negative: number;
	abstain: number;
	didNotVote: number;
	totalResponses: number;
	totalBallots: number;
}


export function useBallots({ committeeSite, contentDef }: { committeeSite: CommitteeSite | undefined, contentDef: ContentDefinition }) {
	const [currentPage, setCurrentPage] = useState(0);

	const query = new URLSearchParams();
	query.set('page', `${currentPage}`);

	const { data, error, isLoading } = useSWR<PagedResult<Ballot>>(!committeeSite ? null : `/api/Ballots/GetBallots/${committeeSite.siteId}/${contentDef.id}?${query}`, {
		use: [dateParser<Ballot>('startingDate', 'closingDate'), deferredUpdate]
	});

	return {
		ballots: data?.items,
		isLoading,
		error,
		currentPage,
		totalPages: data?.totalPages,
		switchPage(page: number) {
			if (!data?.totalPages || page < 0 || page >= data?.totalPages) return;
			setCurrentPage(page);
		},
	};
}

export function useBallotDetails(ballotId?: number) {
	const { data: ballotDetails, error, isLoading } = useSWR<BallotDetails>(!ballotId ? null : `/api/Ballots/GetBallotDetails/${ballotId}`);

	return {
		ballotDetails,
		isLoading,
		error
	};
}
