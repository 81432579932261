import { useState } from 'react';
import useSWR from 'swr';

import { PagedResult } from '../utils/paging-utils';
import { dateParser, deferredUpdate } from '../utils/swr-config';

import genericIcon from '../assets/icon-txt.svg';
import pdfIcon from '../assets/icon-pdf.png';
import wordIcon from '../assets/icon-word.png';
import excelIcon from '../assets/icon-excel.svg';
import powerPointIcon from '../assets/icon-powerpoint.svg';
import htmlIcon from '../assets/icon-html.svg';
import folderIcon from '../assets/icon-folder.svg';
import { LibraryContentDefinition, DocumentLibrarySortBy } from './use-committee-site-details';

export interface DocumentLibraryItemBase {
	id: string;
	type: DocumentType;
	name: string;
	url: string;
	driveId: string;
	lastModified: Date;
	lastModifiedBy: string;
}

export interface DocumentLibraryFolder extends DocumentLibraryItemBase {
	type: DocumentType.Folder;
	childCount: number;
}

export interface DocumentLibraryFile extends DocumentLibraryItemBase {
	type: DocumentType.GenericFile;
	mimeType: string;
}

export type DocumentLibraryItem = DocumentLibraryFolder | DocumentLibraryFile;

export enum DocumentType {
	GenericFile = 0,
	Pdf,
	Word,
	Excel,
	PowerPoint,
	Html,
	Folder
}

export function getDocumentIcon(docType: DocumentType) {

	switch (docType) {
		case DocumentType.GenericFile:
			return genericIcon
		case DocumentType.Pdf:
				return pdfIcon;
		case DocumentType.Word:
				return wordIcon;
		case DocumentType.PowerPoint:
				return powerPointIcon;
		case DocumentType.Excel:
				return excelIcon;
		case DocumentType.Html:
				return htmlIcon;
		case DocumentType.Folder:
				return folderIcon;
		default:
				return genericIcon;
	}

}

export function useDocumentLibrary(committeeId: string | undefined, contentDef: LibraryContentDefinition) {
	const [currentPage, setCurrentPage] = useState(0);
	const [currentPath, setCurrentPath] = useState<DocumentLibraryFolder[]>([]);

	const [sortBy, setSortBy] = useState<DocumentLibrarySortBy>(contentDef.documentLibraryParameters?.defaultSortBy ?? DocumentLibrarySortBy.LastModified);
	const [sortDir, setSortDir] = useState<number>(contentDef.documentLibraryParameters?.defaultSortDir ?? -1);

	const params = new URLSearchParams();
	if (currentPath.length > 0) params.append('folderId', currentPath[0].id);
	params.append('page', `${currentPage}`);
	params.append('sortBy', `${sortBy}`);
	params.append('sortDir', `${sortDir}`);

	const { data, error, isLoading, mutate } = useSWR<PagedResult<DocumentLibraryItem>>(committeeId && contentDef.listLibraryId ? `/api/documents/get/${committeeId}/${contentDef.listLibraryId}?${params.toString()}` : null, {
		use: [dateParser<DocumentLibraryItem>('lastModified'), deferredUpdate]
	});

	return {
		documents: data?.items,
		isLoading,
		error,
		currentPage,
		currentPath,
		totalPages: data?.totalPages,
		accessDenied: data?.accessDenied ?? false,
		canSort: data?.canSort ?? false,
		sortBy,
		sortDir,
		setSort: (newSortBy: DocumentLibrarySortBy) => {
			if (newSortBy === sortBy) {
				setSortDir(dir => dir * -1);
			} else {
				setSortBy(newSortBy);
			}
		},
		switchPage: (page: number) => {
			if (!data?.totalPages || page < 0 || page >= data?.totalPages) return;
			setCurrentPage(page);
		},
		goBack: () => {
			setCurrentPath(currentPath.slice(1));
			setCurrentPage(0);
		},
		openFolder: (folder: DocumentLibraryFolder | null) => {
			if (folder === null) {
				setCurrentPath([]);
				setCurrentPage(0);
				return;
			}

			const newHistory = [...currentPath];
			while (newHistory.some(p => p.id === folder.id)) newHistory.shift();
			setCurrentPath([folder, ...newHistory]);
			setCurrentPage(0);
		},
		uploadDocument: async (documentUpload: File) => {
			if (!documentUpload || !contentDef.listLibraryId) return false;

			const formData = new FormData();
			formData.append('File', documentUpload);

			const paramsUpload = new URLSearchParams();
			if (currentPath.length > 0) paramsUpload.append('folderId', currentPath[0].id);
			params.append('page', `${currentPage}`);
			params.append('sortBy', `${sortBy}`);
			params.append('sortDir', `${sortDir}`);


			const res = await fetch(`/api/documents/upload/${committeeId}/${contentDef.listLibraryId}?${paramsUpload.toString()}`, {
				method: 'POST',
				body: formData
			});

			if (!res.ok) {
				console.error(await res.text());
				return false;
			}

			const updatedDocumentLibraryItems = await res.json() as PagedResult<DocumentLibraryItem>;
			updatedDocumentLibraryItems.items = updatedDocumentLibraryItems.items.map(item => ({ ...item, lastModified: new Date(item.lastModified) }));
			await mutate(updatedDocumentLibraryItems, { revalidate: false });

			return true;
		}
	};
}