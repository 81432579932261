import { saveAs } from 'file-saver';

export async function downloadFileById(committeeId: string, driveId: string, fileId: string, fileName: string) {
	const url = `/api/documents/download/${committeeId}/${driveId}/${fileId}`;

	const res = await fetch(url);

	if (!res.ok) {
		const body = await res.text();
		console.error(`Error downloading file: ${body}`);
		throw new Error(`Error downloading file: ${body}`);
	}

	const blob = await res.blob();
	saveAs(blob, fileName);
};


export async function downloadSharepointFile(committeeId: string | undefined, docLibUrl: string, fileName: string) {
	const url = `/api/documents/linkclicked/${committeeId}?url=${encodeURI(docLibUrl)}&fileName=${encodeURI(fileName)}`;

	const res = await fetch(url);

	if (!res.ok) {
		const body = await res.text();
		console.error(`Error downloading file: ${body}`);
		throw new Error(`Error downloading file: ${body}`);
	}

	const blob = await res.blob();
	saveAs(blob, decodeURI(fileName));
};