import { useEffect, useCallback, useRef } from 'react';
import { SWRConfig, Middleware } from 'swr';
import { isPagedResult } from './paging-utils';


export const swrConfig: React.ComponentProps<typeof SWRConfig>['value'] = {
	fetcher,
	revalidateOnFocus: false,
};

export async function fetcher<T>(url: string) {
	const res = await fetch(url);
	if (res.ok) return await res.json() as T;

	const body = await res.text();
	let error: any;
	try {
		error = JSON.parse(body);
	} catch(e) {
		error = new Error(body);
	}

	console.error(error);
	throw error;
}

export function dateParser<TData>(...dateFields: (keyof TData & string)[]): Middleware {

	return function dateParserMiddleware(useSWRNext) {
		return (key, fetcher, config) => {
			const extendedFetcher = async (args: string) => {

				if (!fetcher) return;

				const result = await fetcher(args) as any;

				if (Array.isArray(result)) {
					return result.map(obj => parseDates(obj, dateFields));
				}

				// If it matches the PagedResult interface, parse the data array.
				if (isPagedResult(result)) {
					result.items = result.items.map((obj: unknown) => parseDates(obj, dateFields));
					return result;
				}

				return parseDates(result, dateFields);
			};

			return useSWRNext(key, extendedFetcher, config)
		}
	}

	function parseDates(obj: any, dateFields: string[]) {
		if (!obj || typeof obj !== 'object') return obj;

		for (const field of dateFields) {
			if (typeof obj[field] === 'string') obj[field] = new Date(obj[field] as string) as unknown as TData[keyof TData];
		}
		return obj;
	}
}

// This is a SWR middleware for keeping the data even if key changes.
export const deferredUpdate: Middleware = (useSWRNext) => {
	return (key, fetcher, config) => {
		// Use a ref to store previous returned data.
		const deferredDataRef = useRef<any>()

		// Actual SWR hook.
		const swr = useSWRNext(key, fetcher, config)

		useEffect(() => {
			// Update ref if data is not undefined.
			if (swr.data !== undefined) {
				deferredDataRef.current = swr.data
			}
		}, [swr.data])

		// Expose a method to clear the deferred data, if any.
		const resetDeferred = useCallback(() => {
			deferredDataRef.current = undefined
		}, []);

		// Fallback to previous data if the current data is undefined.
		const dataOrDeferredData = swr.data === undefined ? deferredDataRef.current : swr.data

		// Is it showing previous data?
		const isDeferred = swr.data === undefined && deferredDataRef.current !== undefined

		// Also add a `isLagging` field to SWR.
		return Object.assign({}, swr, {
			data: dataOrDeferredData,
			isDeferred: isDeferred,
			resetDeferred: resetDeferred,
		})
	}
};