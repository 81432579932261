import React, { useState } from 'react';

import { LibraryContentDefinition, useCommitteeSiteContext } from '../hooks/use-committee-site-details'
import { useCalendarEvents, Event } from '../hooks/use-calendar-events';
import { CalendarDetails } from '../components/CalendarEventsDetails';
import { PagingControls } from '../components/PagingControls';
import { LoadingOverlay } from '../components/LoadingOverlay';
import { dateFormatter } from '../utils/date-utils';
import { ContentHeader } from './ContentHeader';

import { Modal } from './Modal';

export function CalendarEventsRenderer({ contentDef }: { contentDef: LibraryContentDefinition }) {
	const { committeeSite } = useCommitteeSiteContext();
	const { results, error, isLoading, currentPage, switchPage, totalPages } = useCalendarEvents(committeeSite?.siteId, contentDef.listLibraryId);
	const [event, setEvent] = useState<Event>();

	if (error) {
		return (<>
			<ContentHeader contentDefinition={contentDef} forceTitle={true} />
			<pre>{error.toString()}</pre>
		</>);
	}

	if (isLoading) return <p>Loading events...</p>;

	return <>
		<LoadingOverlay isLoading={isLoading}>
			<ContentHeader contentDefinition={contentDef} />
			{results?.length === 0 && <div>No Calendar Events</div>}
			<div className="row">
				<div className="col-12">
					<ul className="calendar unlisted">
						{results?.map(event =>
							<li key={event.id}>
								<span>{dateFormatter(event.startTime, event.allDayEvent)}</span> <a role="button" onClick={() => setEvent(event)}>{event.title}</a>
							</li>
						)}
					</ul>
				
				</div>
			</div>
			<PagingControls {...{ currentPage, switchPage, totalPages, forTable: false }} />
			<Modal isOpen={!!event} onClose={() => setEvent(undefined)}>
				{event && <CalendarDetails details={event} />}
			</Modal>
		</LoadingOverlay>
	</>;
}