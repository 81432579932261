import { User } from './use-current-user';

export function useBanner(user: User | undefined) {
	const banner = window.myc.banner;

	if (!banner || !banner.message) {
		return null;
	}

	if (banner.showFor === 'All') {
		return banner;
	}

	if (banner.showFor === 'Anonymous' && !user) {
		return banner;
	}

	if (banner.showFor === 'Authenticated' && user) {
		return banner;
	}

	return null;
}