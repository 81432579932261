import clsx from 'clsx';
import React, { PropsWithChildren, useEffect, useCallback } from 'react';


export function Modal({ isOpen, onClose, children }: PropsWithChildren<{ isOpen: boolean, onClose: () => void }>) {

	useEffect(() => {
		document.body.style.overflow = isOpen ? 'hidden' : 'unset';
	}, [isOpen]);

	const keyupHandler = useCallback((event: KeyboardEvent) => {
    if (event.key === "Escape") onClose();
  }, [onClose]);

  useEffect(() => {
    document.addEventListener("keyup", keyupHandler, false);

    return () => {
      document.removeEventListener("keyup", keyupHandler, false);
    };
  }, [keyupHandler]);

	return (
		<div className={clsx('modal', isOpen && 'modal--open')}>
			<div className="modal__shade" onClick={onClose}></div>
			<div className="modal__content">
				<i className="modal__content__close fa-solid fa-xmark" onClick={onClose}></i>
				{children}
			</div>
		</div>
	);
}