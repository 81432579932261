


export interface PagedResult<T> {
	totalPages?: number;
	totalItems?: number;
	currentPage: number;
	items: T[];
	canSort: boolean;
	accessDenied: boolean;
}

export function isPagedResult<T>(data: any): data is PagedResult<T> {
	return data && typeof data === 'object' && 'currentPage' in data && 'items' in data;
}