import React from 'react'

import { LibraryContentDefinition, useCommitteeSiteContext } from '../hooks/use-committee-site-details'
import { useLinksList } from '../hooks/use-links-list';
import { PagingControls } from '../components/PagingControls';
import { LoadingOverlay } from '../components/LoadingOverlay';
import { ContentHeader } from './ContentHeader';

export function LinkListRenderer({ contentDef }: { contentDef: LibraryContentDefinition }) {
	const { committeeSite } = useCommitteeSiteContext();
	const { results, error, isLoading, currentPage, switchPage, totalPages } = useLinksList(committeeSite?.siteId, contentDef.listLibraryId!);

	if (error) {
		return (<>
			<ContentHeader contentDefinition={contentDef} forceTitle={true} />
			<pre>{error.toString()}</pre>
		</>);
	}

	return <>
		<LoadingOverlay isLoading={isLoading}>
			<ContentHeader contentDefinition={contentDef} />
			{!contentDef.listLibraryId && <div className="text-danger">Unknown link list "{contentDef.listLibraryName}".</div>}
			{results?.length === 0 && <div>No Links</div>}
			<div className="row">
				<div className="col-12">
					<ul className="link-list unlisted">
							{results?.map(link =>
								<li key={link.id}>
									<a href={link.urlLink}>{link.urlText}</a>
										{link.comments && <p>{link.comments}</p>}
									</li>
							)}
					</ul>
				</div>
			</div>
			<PagingControls {...{ currentPage, switchPage, totalPages, forTable: false }} />
		</LoadingOverlay>
	</>;
}