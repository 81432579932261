import React from 'react';
import { Container, Row, Col } from 'reactstrap';

export function Footer() {
	return <footer>
		<Container fluid>
			<Row>
				<Col>
					<ul className="unlisted inline">
						<li><a href="http://www.api.org/privacy">Privacy Policy</a></li>
						<li><a href="http://www.api.org/terms-and-conditions">Terms and Conditions</a></li>
						<li><a href="http://www.api.org/terms-and-conditions">Legal Notices</a></li>
						<li>&copy; Copyright {(new Date()).getFullYear()} &bull; API, All rights reserved</li>
					</ul>
				</Col>
			</Row>
		</Container>
	</footer>
}