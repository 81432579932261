import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { SWRConfig } from 'swr';

import { AppInsightsConfig } from './utils/app-insights';
import { swrConfig } from './utils/swr-config';
import AppRoutes from './AppRoutes';

import './styles/styles.scss';

export default function App() {

	return (
		<AppInsightsConfig>
			<SWRConfig value={swrConfig}>
				<RouterProvider router={AppRoutes} />
			</SWRConfig>
		</AppInsightsConfig>
	);
}
