import { useState } from 'react';
import useSWR from 'swr';

import { DocumentLibraryFile } from '../hooks/use-document-library';
import { dateParser, deferredUpdate } from '../utils/swr-config';
import { PagedResult } from '../utils/paging-utils';

export interface DocumentSearchItem extends DocumentLibraryFile {
	siteId: string;
	listLibraryId: string;
	driveId: string;
	summary?: string;
}

export function useSearchResults(searchTerm: string) {
	searchTerm = searchTerm?.trim();

	const [currentPage, setCurrentPage] = useState(0);
	const query = new URLSearchParams();
	query.set('page', `${currentPage}`);
	if (searchTerm) query.set('searchTerm', searchTerm);

	const { data, error, isLoading } = useSWR<PagedResult<DocumentSearchItem>>(searchTerm ? `/api/search/get?${query}` : null, {
		use: [dateParser('lastModified'), deferredUpdate]
	});

	return {
		results: data?.items,
		isLoading,
		error,
		currentPage,
		totalPages: data?.totalPages,
		totalItems: data?.totalItems,
		switchPage: (page: number) => {
			if (!data?.totalPages || page < 0 || page >= data?.totalPages) return;
			setCurrentPage(page)
		}
	};
};