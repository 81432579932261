import React, { useState, useEffect } from 'react';

export function Spinner({ children }: { children?: React.ReactNode }) {
	const [count, setCount] = useState(0);

	useEffect(() => {
		setInterval(() => {
			setCount(old => (old + 1) % 4);
		}, 500);
	}, []);


	return <>{children ?? <>Loading</>}{Array(count).fill('.').join('')}</>;
}