import { useState } from 'react';
import useSWR from 'swr';

import { PagedResult } from '../utils/paging-utils';
import { dateParser, deferredUpdate } from '../utils/swr-config';

export interface LinksListItem {
	id: string;
	urlLink: string;
	urlText: string;
	comments: string
	lastModified: Date;
	lastModifiedBy: string;
}

export function useLinksList(committeeId: string | undefined, listId: string) {
	const [currentPage, setCurrentPage] = useState(0);
	const query = new URLSearchParams();
	query.set('page', `${currentPage}`);

	const { data, error, isLoading } = useSWR<PagedResult<LinksListItem>>((committeeId && listId) ? `/api/linkslist/get/${committeeId}/${listId}?${query}` : null, {
		use: [dateParser<LinksListItem>('lastModified'), deferredUpdate]
	});

	return {
		results: data?.items,
		isLoading,
		error,
		currentPage,
		totalPages: data?.totalPages,
		totalItems: data?.totalItems,
		switchPage: (page: number) => {
			if (!data?.totalPages || page < 0 || page >= data?.totalPages) return;
			setCurrentPage(page)
		}
	};
};