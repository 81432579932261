

export async function clearCacheByKey(siteId: string | undefined) {
	if (!siteId) return;
	const success = await fetch(`/api/cache/clearByKey/${siteId}`, { method: 'POST' })
		.then(res => res.json())
		.catch(err => {
			console.error(err);
			return false;
		});
	if (success) window.location.reload();
}

export async function clearAllCache() {
	const success = await fetch(`/api/cache/clearAll`, { method: 'POST' })
		.then(res => res.json())
		.catch(err => {
			console.error(err);
			return false;
		});
	if (success) window.location.reload();
}