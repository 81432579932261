import React, { MouseEvent, useState, useEffect } from 'react';
import { useCurrentUser } from '../hooks/use-current-user';
import { Col, Row } from 'reactstrap';

import { RichTextContentDefinition, useCommitteeSiteContext } from '../hooks/use-committee-site-details';
import { downloadSharepointFile } from '../utils/file-utils';

import { Spinner } from './Spinner';
import { RichTextEditor } from './RichTextEditor';
import { ContentHeader, ContentHeaderButton } from './ContentHeader';


export function RichContentRenderer({ contentDef }: { contentDef: RichTextContentDefinition }) {
	const { committeeSite, committeeSiteDetails } = useCommitteeSiteContext();
	const [editMode, setEditMode] = useState(false);
	const [draft, setDraft] = useState(contentDef.staticContent);
	const [isSaving, setIsSaving] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const { user } = useCurrentUser();
	const [canEdit, setCanEdit] = useState(false);

	const sharePointDomainAndRoot = "https://energyapi.sharepoint.com"; // /teams/myc-

	useEffect(() => {
		setCanEdit(committeeSiteDetails?.userRoles.isOwner || committeeSiteDetails?.userRoles.isEditor || false);
	}, [committeeSiteDetails?.userRoles.isEditor, committeeSiteDetails?.userRoles.isOwner]);

	function handleClick(event: MouseEvent) {
		if (!event.target) return;

		const anchor = (event.target as Element).closest('a[href]') as HTMLAnchorElement | null;
		if (!anchor) return;

		const href = anchor.toString();
		if (!href) return;

		if (!user && href.toLowerCase().includes(sharePointDomainAndRoot)) {
			event.preventDefault();
			event.stopPropagation();

			const path = href.replace(sharePointDomainAndRoot, '');

			const filename = href.substring(href.lastIndexOf('/') + 1);
			const url = path.substring(path.indexOf('/') + 1).replace(`/${filename}`, '');
			downloadSharepointFile(committeeSite?.siteId, url, filename);
			return;
		}

		if (href.startsWith('http')) {
			event.preventDefault();
			event.stopPropagation();
			window.open(href, '_blank');
		}
	}

	return (<>
		<ContentHeader contentDefinition={contentDef}>
			{!editMode && user && canEdit && <ContentHeaderButton icon="fa-solid fa-pen-to-square" label="Edit" onClick={() => setEditMode(true)} disabled={isSaving} />}
			{editMode && <ContentHeaderButton icon="fa-regular fa-save" label="Save" onClick={onSave} disabled={isSaving} />}
			{editMode && <ContentHeaderButton icon="fa-solid fa-arrow-rotate-left" label="Cancel" onClick={onCancel} disabled={isSaving} />}
			{isSaving && <span className="ps-4"><Spinner>Saving</Spinner></span>}
			{errorMessage && <span className="ps-4 text-danger">{errorMessage}</span>}
		</ContentHeader>
		<Row>
			{editMode
				? <RichTextEditor value={contentDef.staticContent} onChange={setDraft} isLoading={isSaving} />
				: <Col dangerouslySetInnerHTML={{ __html: contentDef.staticContent }} onClick={handleClick} />}
		</Row>
	</>);

	async function onSave() {
		setIsSaving(true);
		setErrorMessage(null);
		
		const error = await contentDef.updateRichText(draft);

		setIsSaving(false);
		if (error) {
			setErrorMessage(error);
		} else {
			setEditMode(false);
		}
	}

	function onCancel() {
		setEditMode(false);
		setErrorMessage(null);
		setDraft(contentDef.staticContent);
	}
}
