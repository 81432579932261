import React, { useEffect, useState } from 'react';

// Create a list of listeners that we can modify later.
let listeners: ((entry: ResizeObserverEntry) => void)[] = [];

// Create a single ResizeObserver instance to handle all elements.
const resizeObserver = window.ResizeObserver ? new ResizeObserver((entries) => {
	for (const entry of entries) {
		for (const listener of listeners) {
			listener(entry);
		}
	}
}) : null;


export function useContainerSize(containerRef: React.RefObject<HTMLElement>) {

	const [contentRect, setContentRect] = useState<DOMRectReadOnly | null>(null);

	useEffect(() => {
		const container = containerRef.current;
		const resizeHandler = (entry: ResizeObserverEntry) => {
			if (entry.target === container) setContentRect(entry.contentRect);
		};
		if (container && resizeObserver) {
			listeners.push(resizeHandler)
			resizeObserver.observe(container);
			//console.log(`Active Listeners: ${listeners.length} ++`);
		}

		return () => {
			if (container && resizeObserver) resizeObserver.unobserve(container)
			listeners = listeners.filter((listener) => listener !== resizeHandler);
			//console.log(`Active Listeners: ${listeners.length} --`);
		};
	}, [containerRef]);

	return [contentRect];
}