import { useEffect, createContext, useContext } from 'react';
import useSWR, { KeyedMutator } from 'swr';
import { useLocation } from 'react-router-dom';

import { CommitteeSite, useCommitteeSites, isRootSite } from './use-committee-sites';
import { pipe } from '../utils/func-utils';

export interface CommitteeSiteDetails {
	committeeSite: CommitteeSite;
	teamsUrl?: string;
	contactListId: string;
	contacts: Contact[];
	contentListId: string;
	content: ContentDefinition[];
	userRoles: UserRoles;
}

export interface UserRoles {
	isOwner: boolean;
	isEditor: boolean;
}

export enum ContentType {
	Unknown = 0,

	RichText,
	DocumentLibrary,
	LinkList,
	Survey,
	CalendarEvents,
	Ballot,

	FullRow,
	TwoColumnRow,
	ThreeColumnRow,
	OneThirdLeftRow,
	OneThirdRightRow,

	Column,
}

export interface Contact {
	id: string;
	firstName: string;
	lastName: string;
	email?: string;
	phone?: string;
	details?: string;
}

export interface BaseContentDefinition<T extends ContentType> {
	id: string;
	listItemId: number;
	name: string;
	contentType: T;
	contentIndex: number;
	parentContentId?: string;
	parentListItemId?: number;
	collapsed: boolean;
	showTitle: boolean;
}

export type ContentDefinition = RowContentDefinition | ColumnContentDefinition | RichTextContentDefinition | LibraryContentDefinition | BasicContentDefinition;

export interface RowContentDefinition extends BaseContentDefinition<ContentType.FullRow | ContentType.TwoColumnRow | ContentType.ThreeColumnRow | ContentType.OneThirdLeftRow | ContentType.OneThirdRightRow> {
	children: ContentDefinition[];
}

export interface ColumnContentDefinition extends BaseContentDefinition<ContentType.Column> {
	children: ContentDefinition[];
}

export interface RichTextContentDefinition extends BaseContentDefinition<ContentType.RichText> {
	staticContent: string;
	updateRichText: (content: string) => Promise<string | null>;
}

export interface LibraryContentDefinition extends BaseContentDefinition<ContentType.DocumentLibrary | ContentType.LinkList | ContentType.CalendarEvents> {
	listLibraryName: string;
	listLibraryUrl: string;
	listLibraryId?: string;
	documentLibraryParameters?: { defaultSortBy: DocumentLibrarySortBy, defaultSortDir: number, customSecurity: boolean },
}

export interface BasicContentDefinition extends BaseContentDefinition<ContentType.Ballot | ContentType.Survey> { }

export function isRow(contentDef: ContentDefinition): contentDef is RowContentDefinition {
	return contentDef.contentType === ContentType.FullRow || contentDef.contentType === ContentType.TwoColumnRow || contentDef.contentType === ContentType.ThreeColumnRow || contentDef.contentType === ContentType.OneThirdLeftRow || contentDef.contentType === ContentType.OneThirdRightRow;
}

export function isWebPart(contentDef: ContentDefinition): contentDef is RichTextContentDefinition | LibraryContentDefinition | BasicContentDefinition {
	return contentDef.contentType === ContentType.RichText || contentDef.contentType === ContentType.DocumentLibrary || contentDef.contentType === ContentType.LinkList || contentDef.contentType === ContentType.CalendarEvents || contentDef.contentType === ContentType.Ballot || contentDef.contentType === ContentType.Survey;
}

export function isLibrary(contentDef: ContentDefinition): contentDef is LibraryContentDefinition {
	return contentDef.contentType === ContentType.DocumentLibrary || contentDef.contentType === ContentType.LinkList || contentDef.contentType === ContentType.CalendarEvents;
}

export type UpdateRichTextMutation = (content: string) => Promise<boolean>;

export const SiteDetailsContext = createContext<{ committeeSite?: CommitteeSite, committeeSiteDetails?: CommitteeSiteDetails }>({});

export enum DocumentLibrarySortBy {
	Unknown = 0,
	Name,
	LastModified,
}

export function useCommitteeSiteContext() {
	return useContext(SiteDetailsContext);
}

export function useCommitteeSiteDetails(siteSlug: string | undefined) {
	const { state } = useLocation();
	const { committeeSites } = useCommitteeSites();

	// If we have committees, but no slug, then we're on the root committee page.
	if (committeeSites && !siteSlug) {
		const rootCommittee = committeeSites.find(isRootSite);
		if (!rootCommittee) console.warn(`Cannot find root committee site.`, committeeSites);
		siteSlug = rootCommittee?.slug;
	}
	const { data, error, isLoading, mutate } = useSWR<CommitteeSiteDetails>(siteSlug ? `/api/committeeSites/getDetails/${siteSlug}` : null);
	let committeeSiteDetails: CommitteeSiteDetails | undefined;

	if (data) {
		// Create a copy of the item since we are dealing with object and array references (which SWR will mutate).
		committeeSiteDetails = { ...data };
		committeeSiteDetails.content = pipe(data.content, prepareContentDefs({ committeeSiteDetails, mutate }), buildContentTree, simplifyContentTree);
	}

	const committeeSite = committeeSiteDetails?.committeeSite ?? committeeSites?.find(c => c.slug === siteSlug) ?? (state?.committeeSite as CommitteeSite | undefined);
	

	return {
		committeeSite,
		committeeSiteDetails,
		isLoading,
		error,
	};
}

function prepareContentDefs({ committeeSiteDetails, mutate }: { committeeSiteDetails: CommitteeSiteDetails | undefined, mutate: KeyedMutator<CommitteeSiteDetails> }) {
	return (content: ContentDefinition[]) => {
		const preparedContent: ContentDefinition[] = [];

		for (let i = 0; i < content.length; i++) {
			const contentDef = { ...content[i] };
			if (isRow(contentDef) || contentDef.contentType === ContentType.Column) contentDef.children = [];

			if (contentDef.contentType === ContentType.RichText) {
				contentDef.staticContent ||= '';
				contentDef.updateRichText = async (updatedContent) => {
					if (!committeeSiteDetails?.committeeSite) {
						console.warn(`No committee site found for ${contentDef.name}`, committeeSiteDetails);
						return `Save failed: ${contentDef.name} is unknown.`;
					}

					const res = await fetch(`/api/RichText/Set/${committeeSiteDetails.committeeSite.siteId}/${contentDef.listItemId}`, {
						method: 'POST',
						body: JSON.stringify({ updatedHtmlContent: updatedContent }),
						headers: {
							"Content-Type": "application/json",
						},
					});

					if (!res.ok) {
						const errorMessage = await res.text();
						console.error(errorMessage);
						if (res.status === 401 || res.status === 403) return `Save failed: You are not authorized to edit this content.`;

						return `Save failed: ${errorMessage ?? res.statusText}`;
					}

					const updatedCommitteeDetails = await res.json() as CommitteeSiteDetails;
					await mutate(updatedCommitteeDetails, { revalidate: false });

					return null;
				};
			}

			preparedContent.push(contentDef);
		}

		return preparedContent;
	};
}

function buildContentTree(content: ContentDefinition[]): ContentDefinition[] {
	const contentTree: ContentDefinition[] = [];

	for (const contentDef of content) {

		if (!contentDef.parentContentId) {
			contentTree.push(contentDef);
			continue;
		}

		const parent = content.find(c => c.id === contentDef.parentContentId);
		if (!parent) {
			console.warn(`Cannot find parent content for ${contentDef.name}`);
			contentTree.push(contentDef);
			continue;
		}

		if (isWebPart(parent)) {
			console.warn(`Cannot add child content to ${parent.name}`);
			contentTree.push(contentDef);
			continue;
		}

		parent.children ??= [];
		parent.children.push(contentDef);
	}

	for (const contentDef of content) {
		if ('children' in contentDef) contentDef.children.sort((a, b) => a.contentIndex - b.contentIndex);
	}

	return contentTree.sort((a, b) => a.contentIndex - b.contentIndex);
}

function simplifyContentTree(content: ContentDefinition[]): ContentDefinition[] {
	const simplifiedContent: ContentDefinition[] = [];

	for (const contentDef of content) {
		if (contentDef.contentType === ContentType.FullRow) {
			simplifiedContent.push(...contentDef.children);
			continue;
		}

		simplifiedContent.push(contentDef);
	}

	return simplifiedContent;
}