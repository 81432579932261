import React from 'react';
import { useSearchParams } from 'react-router-dom';

import {  useSearchResults } from '../hooks/use-search-results';
import {  getDocumentIcon, DocumentType } from '../hooks/use-document-library';
import { PagingControls } from '../components/PagingControls';
import { LoadingOverlay } from '../components/LoadingOverlay';
import { useCurrentUser } from '../hooks/use-current-user';
import { usePageTitle } from '../hooks/use-page-title';
import { DocumentLink } from '../components/DocumentLink';

export function SearchResultsPage() {

	const [queryParams] = useSearchParams();
	const searchTerm = queryParams.get("q") ?? "";
	const { results, error, isLoading, currentPage, switchPage, totalPages, totalItems } = useSearchResults(searchTerm);
	const { user } = useCurrentUser();

	usePageTitle('Search Results');
	
	if (error) return <p><em>{error.toString()}</em></p>;

	if (!results) return <p>Loading ...</p>;

	if (results.length === 0) return <><h1>Search Results</h1><div>Searched: <b>{searchTerm}</b></div>No results</>;

	return <div className="content-area search-results">
		<div className="breadcrumbs"></div>
		<h1 className="h3-styling">{totalItems ?? '?'} results found for "{searchTerm}"</h1>
		<LoadingOverlay isLoading={isLoading}>
			<ul className="unlisted search-results__list">
				{results.map(docItem =>
					<li key={docItem.id}>
						<DocumentLink fileOrFolder={docItem} siteId={docItem.siteId}>
							<img src={getDocumentIcon(docItem.type)} width="16" alt={`${DocumentType[docItem.type]} Icon`} />{docItem.name}
						</DocumentLink>
						<SummaryParagraph summary={docItem.summary} searchTerm={searchTerm}></SummaryParagraph>
					</li>
				)}
			</ul>
			<PagingControls {...{ currentPage, switchPage, totalPages }} />
		</LoadingOverlay>
	</div>;
}

function SummaryParagraph({ summary, searchTerm }: { summary: string | undefined, searchTerm: string }) {
	return <p>{summary}</p>;
	
	try {
		searchTerm = searchTerm.toLowerCase();
		const splitSummary = (summary ?? '')
			.split(new RegExp(`(${searchTerm})`, 'gi'));
	
		return <p>
			{splitSummary.map((text, index) => {

				if (text.toLowerCase() === searchTerm) {
					return <mark key={index}>{text}</mark>;
				}

				return <span key={index}>{text}</span>;
			})}
		</p>;
	} catch (err) {
		console.warn(err);
		return <p>{summary}</p>;
	}
}