import React from 'react';
import { Col, Row } from 'reactstrap';

import { ContentType, ContentDefinition, useCommitteeSiteContext, ColumnContentDefinition, RowContentDefinition } from '../hooks/use-committee-site-details';
import { DocumentLibraryRenderer } from '../components/DocumentLibraryRenderer';
import { RichContentRenderer } from '../components/RichContentRenderer';
import { CalendarEventsRenderer } from '../components/CalendarEventsRenderer';
import { LinkListRenderer } from '../components/LinkListRenderer';
import { Spinner } from '../components/Spinner';
import { BallotsRenderer } from '../components/BallotsRenderer';
import { chunks } from '../utils/array-utils';



export function ContentRenderer({ contentDef }: { contentDef: ContentDefinition }) {
	const { committeeSiteDetails } = useCommitteeSiteContext();

	if (!committeeSiteDetails) return <p><Spinner /></p>;


	switch (contentDef.contentType) {
		case ContentType.Column:
			return <ColumnContentRenderer key={contentDef.id} contentDef={contentDef} />;
		
		case ContentType.FullRow:
			return <FullRowContentRenderer key={contentDef.id} contentDef={contentDef} />;
		case ContentType.TwoColumnRow:
			return <TwoColRowContentRenderer key={contentDef.id} contentDef={contentDef} />;
		case ContentType.ThreeColumnRow:
			return <ThreeColRowContentRenderer key={contentDef.id} contentDef={contentDef} />;
		case ContentType.OneThirdLeftRow:
			return <OneThirdLeftRowContentRenderer key={contentDef.id} contentDef={contentDef} />;
		case ContentType.OneThirdRightRow:
			return <OneThirdRightRowContentRenderer key={contentDef.id} contentDef={contentDef} />;

		case ContentType.RichText:
			return <RichContentRenderer key={contentDef.id} contentDef={contentDef} />;
		case ContentType.DocumentLibrary:
			return <DocumentLibraryRenderer key={contentDef.id} contentDef={contentDef} />;
		case ContentType.CalendarEvents:
			return <CalendarEventsRenderer key={contentDef.id} contentDef={contentDef} />;
		case ContentType.LinkList:
			return <LinkListRenderer key={contentDef.id} contentDef={contentDef} />;
		case ContentType.Ballot:
			return <BallotsRenderer key={contentDef.id} contentDef={contentDef} />
	}

	return <p key={contentDef.id}>{contentDef.name} - Unknown content type: {ContentType[contentDef.contentType]}</p>;
}

function ColumnContentRenderer({ contentDef }: { contentDef: ColumnContentDefinition }) {
	return (<>
		<Row>
			{contentDef.children.map(child =>
				<Col xs="12" key={child.id}>
					<ContentRenderer contentDef={child} />
				</Col>
			)}
		</Row>
	</>);
}

function FullRowContentRenderer({ contentDef }: { contentDef: RowContentDefinition }) {
	return (<Row>
		{contentDef.children.map(child =>
			<Col xs="12" key={child.id}>
				<ContentRenderer contentDef={child} />
			</Col>
		)}
	</Row>);
}

function TwoColRowContentRenderer({ contentDef }: { contentDef: RowContentDefinition }) {

	return (<>
			<Row>
				{contentDef.children.map(child => (
					<Col key={child.id} xs="12" lg="6">
						<ContentRenderer contentDef={child} />
					</Col>
				))}
			</Row>
	</>);
}

function ThreeColRowContentRenderer({ contentDef }: { contentDef: RowContentDefinition }) {

	return (<>
			<Row>
				{contentDef.children.map(child => (
					<Col key={child.id} xs="12" lg="4">
						<ContentRenderer contentDef={child} />
					</Col>
				))}
			</Row>
	</>);
}

function OneThirdLeftRowContentRenderer({ contentDef }: { contentDef: RowContentDefinition }) {

	const pairs = [...chunks(contentDef.children, 2)];

	return (<>
		{pairs.map(([left, right]) => (
			<Row key={left.id}>
				<Col xs="12" lg="4">
					<ContentRenderer contentDef={left} />
				</Col>
				<Col xs="12" lg="8">
					<ContentRenderer contentDef={right} />
				</Col>
			</Row>
		))}
	</>);
}

function OneThirdRightRowContentRenderer({ contentDef }: { contentDef: RowContentDefinition }) {

	const pairs = [...chunks(contentDef.children, 2)];

	return (<>
		{pairs.map(([left, right]) => (
			<Row key={left.id}>
				<Col xs="12" lg="8">
					<ContentRenderer contentDef={left} />
				</Col>
				<Col xs="12" lg="4">
					<ContentRenderer contentDef={right} />
				</Col>
			</Row>
		))}
	</>);
}