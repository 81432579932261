import useSWR, { preload } from 'swr';
import { fetcher } from '../utils/swr-config';

const GET_ALL_COMMITTEE_SITES = '/api/committeeSites/getAll';
preload(GET_ALL_COMMITTEE_SITES, fetcher);

export interface CommitteeSite {
	siteId: string;
	name: string;
	slug?: string;
	sharepointUrl?: string;
	parentSiteId?: string;
	isPublic: boolean;
	committeeIds: number[];
	groupId?: string;
}

export function isRootSite(cs: CommitteeSite) {
	return cs.isPublic && cs.slug && !cs.siteId.startsWith('-') && (!cs.parentSiteId || cs.parentSiteId === cs.siteId);
}

export function useCommitteeSites() {
	const { data: committeeSites, error, isLoading } = useSWR<CommitteeSite[]>(GET_ALL_COMMITTEE_SITES);

	return {
		committeeSites,
		isLoading,
		error,
	};
}
