import React, { useState, useEffect } from 'react';
import { usePageTitle } from '../hooks/use-page-title';


type SyncConfigurationEntry = { siteCollection: string, web: string };

export function ConfiguratorPage() {
	const [functionUrl, setFunctionUrl] = useState('');
	const [entries, setEntries] = useState<SyncConfigurationEntry[]>([{ siteCollection: '', web: '' }]);
	const [responseText, setResponseText] = useState('');
	const [pending, setPending] = useState(false);

	usePageTitle('Configurator');

	useEffect(() => {
		const functionUrl = sessionStorage.getItem('configurator_functionUrl');
		if (functionUrl) setFunctionUrl(functionUrl);

		const entries = sessionStorage.getItem('configurator_entries');
		if (entries) setEntries(JSON.parse(entries));
	}, []);

	useEffect(() => {
		sessionStorage.setItem('configurator_functionUrl', functionUrl);
	}, [functionUrl]);

	useEffect(() => {
		sessionStorage.setItem('configurator_entries', JSON.stringify(entries));
	}, [entries]);

	function addEntry() {
		setEntries([...entries, { siteCollection: '', web: '' }]);
	}

	function updateEntry(index: number, update: Partial<SyncConfigurationEntry>) {
		setEntries(entries.map((entry, i) => i === index ? { ...entry, ...update } : entry));
	}

	function removeEntry(index: number) {
		setEntries(entries.filter((entry, i) => i !== index));
	}

	async function onSubmit() {

		if (!functionUrl) return setResponseText('Please set a function URL.');
		const validEntries = entries.filter(e => e.siteCollection?.trim() && e.web?.trim());

		if (!validEntries.length) return setResponseText('Please add at least one complete entry.');

		setPending(true);
		setResponseText('Sending...');
		const param = validEntries.reduce((acc, entry) => {
			return [...acc, entry.siteCollection.trim() + ',' + entry.web.trim()];
		}, [] as string[]).join(',');

		const res = await fetch(`${functionUrl}&siteUrl=${param}`, {
			method: 'POST'
		});
		const result = `${res.status}: ${res.statusText}\n\n`;
		let message = (await res.text()) ?? '';

		if (message.startsWith('{')) {
			try {
				const data = JSON.parse(message);

				if (data.error) {
					const {error, ...rest} = data;
					message = `${error}

${JSON.stringify(rest, null, 2)})}`;
				}
			} catch(e) {
				console.error(e);
			}
		}

		setResponseText(result + message);
		setPending(false);
	}

	return (<>
		<div className="breadcrumbs"></div>
		<h2>Configurator</h2>
		<div className="row">
			<div className="col-md-12">
				<label className="form-label">Function URL</label>
				<input type="text" className="form-control" name="functionUrl" value={functionUrl} onChange={e => setFunctionUrl(e.target.value)} disabled={pending} />
				<hr/>
			</div>
		</div>
		{entries.map((entry, i) => {
			return (<div className="row align-items-end" key={i}>
				<div className="col-md-5">
					<label className="form-label">Site Collection</label>
					<input type="text" className="form-control" disabled={pending} value={entries[i].siteCollection} onChange={e => updateEntry(i, { siteCollection: e.target.value })} />
				</div>
				<div className="col-md-5">
					<label className="form-label">Web</label>
					<input type="text" className="form-control" disabled={pending} value={entries[i].web} onChange={e => updateEntry(i, { web: e.target.value })} />
				</div>
				<div className="col-md-2">
					<button type="button" disabled={pending} onClick={() => removeEntry(i)}>-</button>
				</div>
				<div className="col-md-12">
					<hr/>
				</div>
			</div>);
		})}

		<div className="row justify-content-between">
			<div className="col-md-2">
				<button type="button" onClick={addEntry} disabled={pending || entries.length > 8}>Add Entry</button>
			</div>
			<div className="col-md-2">
				<button type="button" onClick={onSubmit} disabled={pending}>Submit</button>
			</div>
		</div>
		<div className="row">
			<div className="col-md-12">
				<div dangerouslySetInnerHTML={{ __html: responseText}} />
			</div>
		</div>
	</>);
}
