import React from 'react';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ReactPlugin, AppInsightsErrorBoundary, AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { PropsWithChildren } from 'react';

// *** Add the Click Analytics plug-in. ***
/* const clickPluginInstance = new ClickAnalyticsPlugin();
	 const clickPluginConfig = {
		 autoCapture: true
}; */

export const reactPlugin = new ReactPlugin();
export const appInsights = !window.myc?.APPLICATIONINSIGHTS_CONNECTION_STRING ? null : new ApplicationInsights({
	config: {
		connectionString: window.myc.APPLICATIONINSIGHTS_CONNECTION_STRING,
		extensions: [reactPlugin], // [reactPlugin, clickPluginInstance],
		extensionConfig: {
			[reactPlugin.identifier]: { }
			//[clickPluginInstance.identifier]: clickPluginConfig
		},
		enableAutoRouteTracking: true,
		disableAjaxTracking: false,
		autoTrackPageVisitTime: true,
		enableCorsCorrelation: true,
		enableRequestHeaderTracking: true,
		enableResponseHeaderTracking: true
	}
});

try {
	appInsights?.loadAppInsights();
} catch (err) {
	console.warn(`Failed to load Application Insights:
${err}`);
}

// appInsights?.addTelemetryInitializer((env: ITelemetryItem) => {
// 	env.tags ||= [];

// 	env.tags["ai.cloud.role"] = "testTag";
// });

export function AppInsightsConfig({ children }: PropsWithChildren<{}>) {
	return (<>
		<AppInsightsErrorBoundary onError={(props) => <p>Something went wrong. Support has been notified.</p> } appInsights={reactPlugin}>
			<AppInsightsContext.Provider value={reactPlugin}>
				{children}
			</AppInsightsContext.Provider>
		</AppInsightsErrorBoundary>
	</>);
}


