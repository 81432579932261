import React, { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom'

import { useCommitteeSiteDetails, ContentType, ContentDefinition, SiteDetailsContext, useCommitteeSiteContext } from '../hooks/use-committee-site-details';
import { ContactList } from '../components/ContactInfo';
import { Spinner } from '../components/Spinner';
import { useCurrentUser } from '../hooks/use-current-user';
import { useCommitteeSiteTree } from '../hooks/use-committee-site-tree';
import { CommitteeSite, isRootSite } from '../hooks/use-committee-sites';
import { CommitteeSiteLink } from '../components/CommitteeSiteLink';

import sharepointIcon from '../assets/icon-sharepoint.svg';
import teamsIcon from '../assets/icon-teams.svg';
import { ContentRenderer } from '../components/ContentRenderer';
import { clearCacheByKey } from '../utils/cache-utils';
import { usePageTitle } from '../hooks/use-page-title';

import { Modal } from '../components/Modal';


import { useCommitteeAssociation } from '../hooks/use-committee-association';
import { CommitteeAssociationList } from '../components/SiteCommitteesAssociation';



export function CommitteePage() {
	const { committeeSlug } = useParams();
	const { committeeSite, committeeSiteDetails, error } = useCommitteeSiteDetails(committeeSlug);

	const committeeSiteDetailsContext = useMemo(() => ({ committeeSite, committeeSiteDetails }), [committeeSite, committeeSiteDetails]);

	usePageTitle(committeeSite && !isRootSite(committeeSite) ? committeeSite.name : undefined);

	if (error) {
		if (error.status === 404) {
			return (<div className="content-area">
				<div className="breadcrumbs"></div>
				<h1>Not Found</h1>
				<p>The committee site you requested could not be found.</p>
			</div>);
		}

		if (error.status === 401) {
			window.location.replace(`/auth/login?returnUrl=/${committeeSlug}`);
			return (<div className="content-area">
				<div className="breadcrumbs"></div>
				<p>Authenticating...</p>
			</div>);
		}

		if (error.status === 403) {
			return (<div className="content-area">
					<div className="breadcrumbs"></div>
					<h1>Access Denied</h1>
					<p>You do not have access to this committee site.</p>
				</div>);
		}

		return <h1>{JSON.stringify(error, null, 2)}</h1>;
	}

	return (<>
		<SiteDetailsContext.Provider value={committeeSiteDetailsContext}>
			<CommitteeSiteHeader />
			<CommitteeSiteBody />
		</SiteDetailsContext.Provider>
	</>);
}

function CommitteeSiteHeader() {
	const { committeeSite, committeeSiteDetails } = useCommitteeSiteContext();
	const { user } = useCurrentUser();
	const { currentSitePath } = useCommitteeSiteTree();
	const [committeeIds, setCommitteeIds] = useState<number[]>();

	const showCacheResetButton = (user?.email.endsWith('@api.org') && (committeeSiteDetails?.userRoles.isOwner || committeeSiteDetails?.userRoles.isEditor)) || user?.email.endsWith('@rdacorp.com');
	const showCommitteeAssociationInfo = (committeeSite?.committeeIds != null && committeeSite?.committeeIds.length > 0) && ((user?.email.endsWith('@api.org') && (committeeSiteDetails?.userRoles.isOwner || committeeSiteDetails?.userRoles.isEditor)) || (user?.email.endsWith('@rdacorp.com')));

	return (
		<div className="breadcrumbs">
			<ul className="breadcrumbs__nav unlisted inline">
				<li>{currentSitePath?.length === 0 ? <>MyCommittees</> : <Link to="/">MyCommittees</Link> }</li>
				{currentSitePath?.map(tree => <CommitteeSiteBreadcrumb key={tree.committeeSite.siteId} committeeSite={tree.committeeSite} isActive={tree.active} />)}
			</ul>
			{user && showCacheResetButton && (
				<ul className="breadcrumbs__links unlisted inline subtle">
					<li><a role="button" onClick={() => clearCacheByKey(committeeSite?.siteId)}>Reset Cache</a></li>
				</ul>
			)}
			{user && showCommitteeAssociationInfo && (
				<div>
					<ul className="breadcrumbs__links unlisted inline subtle">
						<li><a role="button" onClick={() => setCommitteeIds(committeeSite?.committeeIds)}>View Linked Committees</a></li>
					</ul>
					<Modal isOpen={!!committeeIds} onClose={() => setCommitteeIds(undefined)}>
						<div>
							<h5>Associated Committees</h5>
							<CommitteeAssociationList committeeIds={committeeIds} />							
						</div>
					</Modal>
				</div>
			)}
			{user && (
				<ul className="breadcrumbs__links unlisted inline">
					<li>Open in</li>
					{committeeSiteDetails?.teamsUrl && <li><Link to={committeeSiteDetails.teamsUrl} target="_blank" rel="noreferrer"><img src={teamsIcon} alt="MS Teams Icon" /></Link></li>}
					{committeeSite?.sharepointUrl && <li><Link to={committeeSite.sharepointUrl} target="_blank" rel="noreferrer"><img src={sharepointIcon} alt="SharePoint Icon" /></Link></li>}
				</ul>
			)}
		</div>
	);
}

function CommitteeSiteBreadcrumb({ committeeSite, isActive }: { committeeSite: CommitteeSite, isActive: boolean }) {
	return (
		<li>
			{(isActive || !committeeSite.slug) ? committeeSite.name : <CommitteeSiteLink committeeSite={committeeSite} />}
		</li>
	);
}

function CommitteeSiteBody() {	
	const { committeeSite, committeeSiteDetails } = useCommitteeSiteContext();
	return (<>
		<div className="content-area">
			<h1>{committeeSite?.name}</h1>

			<ContactList contacts={committeeSiteDetails?.contacts} />
			{committeeSiteDetails?.content?.map(contentDef => <ContentRenderer key={contentDef.id} contentDef={contentDef} />) || <p><Spinner /></p>}
		</div>
	</>);
}