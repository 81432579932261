import React, { PropsWithChildren } from 'react';
import { ContentDefinition } from '../hooks/use-committee-site-details';
import { useCurrentUser } from '../hooks/use-current-user';

export function ContentHeader({ contentDefinition, forceTitle, children }: PropsWithChildren<{ contentDefinition: ContentDefinition, forceTitle?: boolean }>) {
	const { user } = useCurrentUser();

	const headerTitle = contentDefinition.showTitle || forceTitle ? contentDefinition.name : '';

	return (<>
		<h2 className="h4-styling">
				{headerTitle}
				{user && 'listLibraryUrl' in contentDefinition && <ContentHeaderLink icon="fa-solid fa-pen-to-square" label="Edit in SharePoint" href={contentDefinition.listLibraryUrl} />}
				{user && children}
			</h2>
	</>);
}

export type IconClass = `fa-solid fa-${string}` | `fa-regular fa-${string}`;

export function ContentHeaderLink({ icon, label, href }: { icon: IconClass, label: string, href: string | undefined }) {

	return (<>
		{(href) && (
			<a href={href} target="_blank" rel="noreferrer" className="heading-link">
				<i className={icon}></i>
				<div className="heading-link__popup">{label}</div>
			</a>
		)}
	</>);
}

export function ContentHeaderButton({ icon, label, onClick, disabled }: { icon: IconClass, label: string, onClick?: () => void | Promise<void>, disabled?: boolean }) {
	
	return (<>
		{onClick && (
			<a role="button" onClick={() => disabled ? null : onClick()} className="heading-link">
				<i className={icon}></i>
				<div className="heading-link__popup">{label}</div>
			</a>
		)}
	</>);
}
