import React from 'react';

import styles from './LoadingOverlay.module.scss';

export function LoadingOverlay({ isLoading, backgroundColor, children }: React.PropsWithChildren<{ isLoading: boolean, backgroundColor?: string }>) {
	backgroundColor ??= '#ffffff';
	if (backgroundColor.length === 7) backgroundColor += 'cc'; // 80% opacity

	if (!isLoading) return <>{children}</>;

	return (<div className={styles.loadingOverlayContainer}>
		<div className={styles.loadingOverlay} style={{ backgroundColor }}>Loading...</div>
		{children}
	</div>)
}