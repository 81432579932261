import React, { useEffect, useState } from 'react';
import { clsx } from 'clsx';
import { Link } from 'react-router-dom';

import { CommitteeSiteTree, useCommitteeSiteTree } from '../hooks/use-committee-site-tree';
import { LoadingOverlay } from './LoadingOverlay';
import { CommitteeSiteLink } from './CommitteeSiteLink';

import styles from './CommitteesSideBar.module.scss';

export function CommitteesSideBar() {
	const { committeeSiteTrees, error, isLoading, siteFilter, setSiteFilter } = useCommitteeSiteTree();

	return (
		<div className="sidebar sidebar--with-lines">
			<LoadingOverlay isLoading={isLoading} backgroundColor="#e9ebea">
				{error && <>
					<p>ERROR:</p>
					<pre>{error.toString()}</pre>
				</>}
				{committeeSiteTrees && <CommitteeSiteList committeeSiteTrees={committeeSiteTrees} siteFilter={siteFilter} onSiteFilterChanged={setSiteFilter} />}
			</LoadingOverlay>
		</div>
	);
}

export function CommitteeSiteList({ committeeSiteTrees, siteFilter, onSiteFilterChanged }: { committeeSiteTrees: CommitteeSiteTree[], siteFilter: string, onSiteFilterChanged: (search: string) => void}) {
	return (<>
		<h2><Link to="/">MyCommittees</Link></h2>
		<input type="text" className={styles.siteFilter} placeholder="Filter Committee Sites" value={siteFilter} onChange={e => onSiteFilterChanged(e.target.value)} />
		{siteFilter && committeeSiteTrees.length === 0 && <p className="mt-2">No matching committee sites found.</p>}
		<ul className="unlisted">
			{committeeSiteTrees.filter(t => t.committeeSite.slug || (t.subTrees.length > 0)).map(committeeTree =>
				<CommitteeSiteListItem key={committeeTree.committeeSite.siteId} tree={committeeTree} root={true} />
			)}
		</ul>
	</>);
}

function CommitteeSiteListItem({ tree, root }: { tree: CommitteeSiteTree, root?: boolean }) {
	const [expanded, setExpanded] = useState(tree.expanded);

	useEffect(() => {
		if (tree.expanded) setExpanded(tree.expanded);
	}, [tree.expanded]);
	
	const hasSubCommittees = tree.subTrees.length > 0;

	function scrollTop() {
		document.body.scrollTop = document.documentElement.scrollTop = 0;
	}
	
	return <li className={clsx(hasSubCommittees && 'sidebar__with-sub', expanded && 'sidebar__with-sub--open')}>
		{hasSubCommittees && (
			<button className="sidebar__sub-trigger" onClick={() => setExpanded(!expanded)}><i className="fa-solid fa-caret-right"></i><span className="hidden-for-accessibility">Open Secondary Nav</span></button>
		)}
		{tree.committeeSite.slug && <CommitteeSiteLink committeeSite={tree.committeeSite} className={clsx(tree.active && 'active')} onClick={scrollTop} />}
		{!tree.committeeSite.slug && <span>{tree.committeeSite.name}</span>}
		{hasSubCommittees && (
			<ul className="sidebar__sub unlisted">
				{tree.subTrees.filter(t => t.committeeSite.slug || (t.subTrees.length > 0)).map(subCommitteeTree =>
					<CommitteeSiteListItem key={subCommitteeTree.committeeSite.siteId} tree={subCommitteeTree} />
				)}
			</ul>
		)}
	</li>
}