import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { useSearchParams } from 'react-router-dom';
import clsx from 'clsx';

import { User, useCurrentUser } from '../hooks/use-current-user';

import logo from '../assets/api-logo-white.svg';
import logoSmall from '../assets/api-logo-small-white.svg';

import { clearAllCache } from '../utils/cache-utils';


export function Header() {
	const { user } = useCurrentUser();
	const navigate = useNavigate();

	const [queryParams] = useSearchParams();
	const paramValue = queryParams.get("q") ?? "";
	const [searchTerm, setSearchTerm] = useState(paramValue);

	const goToSearch = () => {
		navigate({
			// Always go to the root because search is global, staying at the slug implies site-scoped search which is not happening now
			pathname: '/search',
			search: '?q=' + searchTerm,
		});
	};

	const KeyPressSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			setSearchTerm(event.currentTarget.value);
			goToSearch()
		}
	}

	return (
		<header>
			<Container fluid>
				<Row>
					<Col xs="12" className="header__main">
						<Link to={`/`} className="header__main__logo"><img src={logo} alt="#" /></Link>
						<Link to={`/`} className="header__main__logo-small"><img src={logoSmall} alt="#" /></Link>
						<div className="header__main__search">
							<div className="form-group">
								<label className="hidden-for-accessibility" htmlFor="headerSearch">Search</label>
								<input type="text" className="form-control" id="headerSearch" placeholder="Search" value={searchTerm} onChange={e => setSearchTerm(e.target.value)}
									onKeyDown={KeyPressSearch}></input>
							</div>
							<button type="button" className="header__main__search__submit" onClick={goToSearch}><i className="fa-solid fa-magnifying-glass"></i><span className="hidden-for-accessibility">Submit</span></button>
						</div>
						<nav className="header__main__nav">
							<ul className="unlisted inline">
								<li><Link to={`/`}>MyCommittees</Link></li>
								{user && <li><Link to={`/rosters`}>My Rosters</Link></li>}
								{user
									? <AccountButton user={user} />
									: <LoginButton />}
							</ul>
						</nav>
					</Col>
				</Row>
			</Container>
		</header>
	);
}

function LoginButton() {
	return (
		<li className="header__main__nav__login"><Link to="/login"><i className="fa-solid fa-circle-user"></i>Login</Link></li>
	);
}

function AccountButton({ user }: { user: User }) {
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		const handler = (e: MouseEvent) => {
			if (e.target instanceof HTMLElement && e.target.closest('.header__main__nav__user')) {
				
				// If they selected one of the menu anchors then close the menu.
				if (e.target instanceof HTMLAnchorElement) setIsOpen(false);
				return;
			}

			setIsOpen(false);
		};
		window.addEventListener('click', handler);
		return () => window.removeEventListener('click', handler);
	}, []);

	return (
		<li className={clsx('header__main__nav__user', isOpen && 'header__main__nav__user--open')}>
			<span className="header__main__nav__user__trigger" onClick={() => setIsOpen(!isOpen)}>{user.initials}</span>
			<ul className="unlisted header__main__nav__user__list">
				<li><span>{user.displayName}</span>{user.email}</li>
				{(user.email.endsWith('@api.org') || user.email.endsWith('@rdacorp.com')) && <li><a role="button" onClick={clearAllCache}>Clear All Cache</a></li>}
				<li><Link to="/logout">Log Out</Link></li>
			</ul>
		</li>
	);
}