import React from 'react';

import { Contact } from '../hooks/use-committee-site-details';
import { Col, Row } from 'reactstrap';

export function ContactList({ contacts }: { contacts: Contact[] | undefined }) {
	return <>
		{contacts?.map(contact => {

			return (
				<Row key={contact.id}>
					<Col>
						<ContactInfo contact={contact} />
					</Col>
				</Row>
			);
		})}
	</>;
}

export function ContactInfo({ contact }: { contact: Contact }) {
	return (
		<div className="contact">
			<div className="contact-info">
				<h2>Committee Contact</h2>
				<ul className="unlisted inline">
					<li><a {...(contact.email ? { href: `mailto:${contact.email}` } : {})}><i className="fa-solid fa-envelope"></i>{contact.firstName} {contact.lastName}</a></li>
					{contact.phone && <li><a href={`tel:${contact.phone}`}><i className="fa-solid fa-phone"></i>{contact.phone}</a></li>}
				</ul>
			</div>
			{contact.details && <div className="contact-details" dangerouslySetInnerHTML={{ __html: contact.details }} />}
		</div>
	);
}