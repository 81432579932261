import useSWR from 'swr';

export interface Committee {
	commmitteeId: number;
	committeeName?: string;
	committeeGuid?: string;
	description?: string;
	startDate?: Date;
	endDate?: Date;
	coordinatorName?: string;
	administratorName?: string;
	isActive?: boolean
}


export function useCommitteeAssociation(committeeIds: number[] | undefined) {

	const qs:any = [];
	committeeIds?.map(id => qs.push('committeeId=' + id));
	const apiUrl: string = `/api/committee/GetCommitteesByIds?${qs.join('&')}`;
		
	const { data, isLoading, error } = useSWR<Committee[]>(committeeIds?.length ? apiUrl : null);
	
	return {
		data,
		isLoading,
		error,
	};
}

