import { useState } from 'react';
import useSWR from 'swr';

import { PagedResult } from '../utils/paging-utils';
import { dateParser, deferredUpdate } from '../utils/swr-config';

export interface Event {
	id: string;
	title: string;
	url: string;
	location: string
	startTime: Date;
	endTime: Date;
	description: string;
	category: CalendarCategory
	allDayEvent: boolean;
	recurrence: boolean;
	lastModified: Date;
	lastModifiedBy: string;
}

export enum CalendarCategory {
	Meeting = 0,
	WorkHours,
	Business,
	Holiday,
	GetTogether,
	Gifts,
	Birthday,
	Anniversary
}

export function useCalendarEvents(committeeId: string | undefined, calendarId: string | undefined) {
	const [currentPage, setCurrentPage] = useState(0);
	const query = new URLSearchParams();
	query.set('page', `${currentPage}`);

	const { data, error, isLoading } = useSWR<PagedResult<Event>>(committeeId && calendarId ? `/api/calendar/getEvents/${committeeId}/${calendarId}?${query}` : null, {
		use: [dateParser<Event>('startTime', 'endTime', 'lastModified'), deferredUpdate]
	});

	return {
		results: data?.items,
		isLoading,
		error,
		currentPage,
		totalPages: data?.totalPages,
		totalItems: data?.totalItems,
		switchPage: (page: number) => {
			if (!data?.totalPages || page < 0 || page >= data?.totalPages) return;
			setCurrentPage(page)
		}
	};
};