import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { LayoutPage } from './pages/LayoutPage';
import { CommitteePage } from './pages/CommitteePage';
import { SearchResultsPage } from './pages/SearchResultsPage';
import { RosterPage } from './pages/RosterPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { ConfiguratorPage } from './pages/ConfiguratorPage';

const baseUrl = document.getElementsByTagName('base')[0]?.getAttribute('href') ?? '/';

const AppRoutes = createBrowserRouter([
	{
		path: '/',
		element: <LayoutPage />,
		children: [
			{
				index: true,
				element: <CommitteePage />
			},
			{
				path: ':committeeSlug',
				element: <CommitteePage />,
			},
			{
				path: 'rosters',
				element: <RosterPage />,
			},
			{
				path: 'rosters/:rosterSlug',
				element: <RosterPage />,
			},
			{
				path: 'search',
				element: <SearchResultsPage />,
			},
			{
				path: 'configurator',
				element: <ConfiguratorPage />,
			},
			{
				path: 'not-found',
				element: <NotFoundPage />,
			},
			{
				path: 'login',
				element: <ServerRoute url="/auth/login" />,
			},
			{
				path: 'logout',
				element: <ServerRoute url="/auth/logout" />,
			},
		]
	}
], {
	basename: baseUrl
});

export default AppRoutes;


function ServerRoute({ url }: { url: string }) {
	window.location.replace(url);
	return null;
}