import { useContext, createContext } from 'react';
import useSWR, { preload } from 'swr';

import { fetcher } from '../utils/swr-config';
import { appInsights } from '../utils/app-insights';

const GET_CURRENT_USER_URL = '/api/user/getCurrentUser';
preload(GET_CURRENT_USER_URL, fetcher);

export interface User {
	firstName?: string;
	lastName?: string;
	displayName: string;
	email: string;
	initials: string;
}

export const CurrentUserContext = createContext<{ user?: User | undefined }>({});

export function useCurrentUser() {
	return useContext(CurrentUserContext);
}

export function useRootFetchCurrentUser() {

	const { data: user } = useSWR<User>(GET_CURRENT_USER_URL);

	if (user && !user.initials) {
		const userNames = ((user.firstName || user.lastName) ? [user.firstName, user.lastName] : user.displayName.split(' ')).filter(Boolean);
		user.initials = userNames.map(name => name![0].toUpperCase()).join('') || user.email.slice(0, 2).toUpperCase();
	}

	if (user && appInsights) {
		appInsights.setAuthenticatedUserContext(user.email);
	}

	return { user };
}