import React, { useRef, useEffect } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

import { quillClassToNestedList, nestedListToQuillClass } from '../utils/quill-indent-fix';

addCustomClassToFormat({ format: 'blockquote', tagName: 'blockquote', className: 'blockquote' });

export function RichTextEditor({ value, onChange, isLoading }: { value: string | undefined, onChange: ((value: string) => void), isLoading?: boolean }) {
	const containerRef = useRef<HTMLDivElement>(null);
	const editorRef = useRef<Quill>();
	const onChangeRef = useRef(onChange);

	useEffect(() => {
		onChangeRef.current = onChange;
	}, [onChange]);

	useEffect(() => {
		if (isLoading) {
			editorRef.current?.disable();
		} else {
			editorRef.current?.enable();
		}
	}, [isLoading]);

	useEffect(() => {
		const container = containerRef.current!;
		editorRef.current = new Quill(container, {
			theme: 'snow',
			scrollingContainer: document.documentElement,
			modules: {
				toolbar: [
					[{ 'header': [1, 2, 3, 4, 5, 6, false] }, { 'font': [] }],
					['bold', 'italic', 'underline', 'strike', { 'script': 'sub' }, { 'script': 'super' }, 'link'],
					['custom-blockquote', 'code-block'],
				
					[{ 'color': [] }, { 'background': [] }],
					[{ 'align': [] }],

					[{ 'list': 'ordered' }, { 'list': 'bullet' }],
					[{ 'indent': '-1' }, { 'indent': '+1' }],
				
					['clean'],
				]
			}
		});
		const editor = editorRef.current;

		editor.on('text-change', () => {
			let html = editor.root.innerHTML;
			html = quillClassToNestedList(html);
			onChangeRef.current?.(html);
		});
	}, []);

	return <div className="editor-container">
		<div ref={containerRef} dangerouslySetInnerHTML={{ __html: nestedListToQuillClass(value || '') }}></div>
	</div>
}

function addCustomClassToFormat({ format, tagName, className }: { format: string, className: string, tagName: string }) {
	const BaseFormat = Quill.import(`formats/${format}`);
	class CustomFormat extends BaseFormat { }
	CustomFormat.blotName = `custom-${tagName}`;
	CustomFormat.tagName = tagName;
	CustomFormat.className = className;
	Quill.register(CustomFormat, true);

	const icons = Quill.import('ui/icons');
	icons[CustomFormat.blotName] = icons[format];
}